export const permissions = [
  'engagement',

  'engagement.survey',
  'engagement.survey.libras',

  'engagement.participations',
  'engagement.participations.variation',
  'engagement.participations.invite',
  'engagement.participations.export.xlsx',
  'engagement.participations.export.pptx',
  'engagement.participations.confirmation.management',
  'engagement.participations.answers.management',

  'engagement.opinions',
  'engagement.opinions.variation',
  'engagement.opinions.tags',
  'engagement.opinions.reports',

  'engagement.groups-comparation',
  'engagement.groups-comparation.variation',
  'engagement.groups-comparation.export.csv',

  'engagement.favorite-filters',

  'engagement.culture',
  'engagement.culture.variation',
  'engagement.culture.export.xlsx',
  'engagement.culture.export.pptx',
  'engagement.culture.demographic-reports',
  'engagement.culture.creator',

  'engagement.climate',
  'engagement.climate.variation',
  'engagement.climate.submetrics',
  'engagement.climate.smart-metrics',
  'engagement.climate.smart-metrics.burnout',
  'engagement.climate.shared-links',
  'engagement.climate.questions',
  'engagement.climate.metrics.lnps',
  'engagement.climate.metrics.enps',
  'engagement.climate.impulse',
  'engagement.climate.export.xlsx',
  'engagement.climate.export.pptx',
  'engagement.climate.export.csv',
  'engagement.climate.download',
  'engagement.climate.demographic-reports',
  'engagement.climate.advanced-results',
  'engagement.climate.correlation-results',

  'engagement.benchmark',
  'engagement.benchmark.variation',
  'engagement.benchmark.results',
  'engagement.benchmark.ranking',
  'engagement.benchmark.internal-ranking',
  'engagement.benchmark.export.xlsx',

  'engagement.action-plans',
  'engagement.action-plans.variation',
  'engagement.action-plans.templates',
  'engagement.action-plans.templates.creator',
  'engagement.action-plans.reports',
  'engagement.action-plans.creator',

  'academy.recommendations',
]

export const _permissions = {
  engagement: 'engagement',

  engagement_survey: 'engagement.survey',
  engagement_survey_libras: 'engagement.survey.libras',

  engagement_participations: 'engagement.participations',
  engagement_participations_variation: 'engagement.participations.variation',
  engagement_participations_invite: 'engagement.participations.invite',
  engagement_participations_export_xlsx:
    'engagement.participations.export.xlsx',
  engagement_participations_export_pptx:
    'engagement.participations.export.pptx',
  engagement_participations_confirmation_management:
    'engagement.participations.confirmation.management',
  engagement_participations_answers_management:
    'engagement.participations.answers.management',

  engagement_opinions: 'engagement.opinions',
  engagement_opinions_variation: 'engagement.opinions.variation',
  engagement_opinions_tags: 'engagement.opinions.tags',
  engagement_opinions_reports: 'engagement.opinions.reports',

  engagement_groups_comparation: 'engagement.groups-comparation',
  engagement_groups_comparation_variation:
    'engagement.groups-comparation.variation',
  engagement_groups_comparation_export_csv:
    'engagement.groups-comparation.export.csv',

  engagement_favorite_filters: 'engagement.favorite-filters',

  engagement_culture: 'engagement.culture',
  engagement_culture_variation: 'engagement.culture.variation',
  engagement_culture_export_xlsx: 'engagement.culture.export.xlsx',
  engagement_culture_export_pptx: 'engagement.culture.export.pptx',
  engagement_culture_demographic_reports:
    'engagement.culture.demographic-reports',
  engagement_culture_creator: 'engagement.culture.creator',

  engagement_climate: 'engagement.climate',
  engagement_climate_variation: 'engagement.climate.variation',
  engagement_climate_submetrics: 'engagement.climate.submetrics',
  engagement_climate_smart_metrics: 'engagement.climate.smart-metrics',
  engagement_climate_smart_metrics_burnout:
    'engagement.climate.smart-metrics.burnout',
  engagement_climate_shared_links: 'engagement.climate.shared-links',
  engagement_climate_questions: 'engagement.climate.questions',
  engagement_climate_metrics_lnps: 'engagement.climate.metrics.lnps',
  engagement_climate_metrics_enps: 'engagement.climate.metrics.enps',
  engagement_climate_impulse: 'engagement.climate.impulse',
  engagement_climate_export_xlsx: 'engagement.climate.export.xlsx',
  engagement_climate_export_pptx: 'engagement.climate.export.pptx',
  engagement_climate_export_csv: 'engagement.climate.export.csv',
  engagement_climate_download: 'engagement.climate.download',
  engagement_climate_demographic_reports:
    'engagement.climate.demographic-reports',
  engagement_climate_advanced_results: 'engagement.climate.advanced-results',
  engagement_climate_correlation_results:
    'engagement.climate.correlation-results',

  engagement_benchmark: 'engagement.benchmark',
  engagement_benchmark_variation: 'engagement.benchmark.variation',
  engagement_benchmark_results: 'engagement.benchmark.results',
  engagement_benchmark_ranking: 'engagement.benchmark.ranking',
  engagement_benchmark_internal_ranking:
    'engagement.benchmark.internal-ranking',
  engagement_benchmark_export_xlsx: 'engagement.benchmark.export.xlsx',

  engagement_action_plans: 'engagement.action-plans',
  engagement_action_plans_variation: 'engagement.action-plans.variation',
  engagement_action_plans_templates: 'engagement.action-plans.templates',
  engagement_action_plans_templates_creator:
    'engagement.action-plans.templates.creator',
  engagement_action_plans_reports: 'engagement.action-plans.reports',
  engagement_action_plans_creator: 'engagement.action-plans.creator',

  academy_recommendations: 'academy.recommendations',
}
