import Vue from 'vue'

/**
 * @type {import('vuex').Module}
 */
export const OpinionBox = {
  state: () => ({
    OpinionBox: {
      opened: false,

      props: {
        selectedOpinion: {
          data: {
            id: '0',
            closed: true,
            isAnonymous: true,
            isRead: true,
            isPositive: true,
            quantitativeQuestion: '',
            qualitativeQuestion: '',
            score: 0,
            rating: 'no-comments',
            lastMessage: {
              name: '',
              photo: '',
            },
            tags: [],
          },
          chunks: {
            data: [],
            loading: true,
            infinite: true,
            limit: 20,
            offset: 0,
          },
          ownerIdentity: {},
        },
        tags: {
          data: [],
          loading: true,
          infinite: true,
          limit: 20,
          offset: 0,
          countTags: 0,
        },
        handleMarkAsReaded: () => {},
        handleMarkAsUnread: () => {},
        handleSendMessage: () => {},
        handleCloseOpinion: () => {},
        handleTagsInfiniteScroll: () => {},
        handleAddTag: () => {},
        handleRemoveTag: () => {},
        handleCreateTag: () => {},
      },
    },
  }),

  getters: {
    getOpinionBoxStatus: ({ OpinionBox }) => {
      return OpinionBox.opened
    },
    getOpinionBoxProps: ({ OpinionBox }) => {
      return OpinionBox.props
    },
  },

  actions: {},

  mutations: {
    openOpinionBox(state) {
      state.OpinionBox.opened = true
    },
    closeOpinionBox(state) {
      state.OpinionBox.opened = false
    },
    setOpinionBoxProps(state, payload) {
      Vue.set(state.OpinionBox, 'props', payload)
    },
  },
}
