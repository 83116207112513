import api from '@/service/Http'

export async function getRecommendationsResults(
  groupID,
  metricID,
  limit = 20,
  offset = 0,
  module = 'climate'
) {
  const params = {}
  if (groupID) {
    Object.assign(params, { groupID })
  }

  if (metricID) {
    Object.assign(params, { metricID })
  }

  Object.assign(params, { limit }, { offset }, { module })
  return (await api()).get('/academy/recommendations', {
    params,
  })
}

export async function getRecommendationDetails(id) {
  return (await api()).get(`/academy/recommendations/${id}`)
}

export async function getRecommendationApproval(id) {
  return (await api()).get(`/academy/recommendations/${id}/approval`)
}

export async function voteRecommendation(id, data) {
  return (await api()).put(`/academy/recommendations/${id}/approval`, data)
}
