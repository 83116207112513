<template>
  <v-dialog v-model="open" @click:outside="closeDialog" width="700px">
    <v-card class="rounded-lg explanation-nps">
      <div class="top-container">
        <div class="title">
          <h3>{{ $t(`${this.path}.title`) }}</h3>
        </div>
        <v-btn icon @click="closeDialog" class="white mr-3 close-dialog-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="body-container">
        <div class="text-container">
          <h4>{{ $t(`${this.path}.firstSubtitle`) }}</h4>
          <span>{{ $t(`${this.path}.text`) }}</span>
        </div>
        <div class="img-container">
          <div class="first-img">
            <img
              src="@/assets/images/ExplanationNPS/image1.png"
              :alt="this.type"
            />
            <div class="text">
              <span>{{ $t('explanationNPS.detractors') }}</span>
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <i class="fi-rr-interrogation" />
                  </span>
                </template>
                <span>{{ $t('explanationNPS.detractorsExplanation') }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="second-img">
            <img
              src="@/assets/images/ExplanationNPS/image2.png"
              :alt="this.type"
            />
            <div class="text">
              <span>{{ $t('explanationNPS.passive') }}</span>
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <i class="fi-rr-interrogation" />
                  </span>
                </template>
                <span>{{ $t('explanationNPS.passiveExplanation') }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="third-img">
            <img
              src="@/assets/images/ExplanationNPS/image3.png"
              :alt="this.type"
            />
            <div class="text">
              <span>{{ $t('explanationNPS.promoters') }}</span>
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <i class="fi-rr-interrogation" />
                  </span>
                </template>
                <span>{{ $t('explanationNPS.promotersExplanation') }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="second-subtitle">
          <span>{{ $t(`${this.path}.secondSubtitle`) }}</span>
        </div>
        <v-divider></v-divider>
        <div class="footer-container">
          <div class="footer">
            <img
              src="@/assets/images/ExplanationNPS/bar.png"
              alt="bar"
              width="100%"
            />
            <div class="text-footer">
              <div class="label">
                <span>{{ $t('explanationNPS.needImprove') }}</span>
                <span>(-100 - 0)</span>
              </div>
              <div class="label">
                <span>{{ $t('explanationNPS.good') }}</span>
                <span>(1 - 50)</span>
              </div>
              <div class="label">
                <span>{{ $t('explanationNPS.great') }}</span>
                <span>(51 - 75)</span>
              </div>
              <div class="label">
                <span>{{ $t('explanationNPS.excelent') }}</span>
                <span>(76 - 100)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExplanationNPS',
  props: {
    type: {
      type: String,
      require: true,
    },
    isOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      open: this.isOpen,
      path: 'explanationNPS.' + this.type,
    }
  },
  methods: {
    closeDialog() {
      this.open = false
      this.$emit('update:isOpen')
    },
  },
  watch: {
    isOpen(newValue) {
      this.open = newValue
    },
    type(newValue) {
      this.path = 'explanationNPS.' + newValue
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
