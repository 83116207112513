const Login = () =>
  import(/* webpackChunkName: "LoginPage" */ '@/pages/Login/LoginPage.vue')
const NotFoundPage = () =>
  import(
    /* webpackChunkName: "NotFoundPage" */
    '@/pages/NotFound/NotFoundPage.vue'
  )

const EngagementPage = () =>
  import(
    /* webpackChunkName: "EngagementPage" */
    '@/pages/EngagementPage/EngagementPage.vue'
  )

const ClimatePage = () =>
  import(
    /* webpackChunkName: "ClimatePage" */
    '@/pages/EngagementPage/Tabs/Climate/ClimatePage.vue'
  )

const CulturePage = () =>
  import(
    /* webpackChunkName: "CulturePage" */
    '@/pages/EngagementPage/Tabs/Culture/CulturePage.vue'
  )
const CultureManagement = () =>
  import(
    /* webpackChunkName: "CulturePage" */
    '@/pages/EngagementPage/Tabs/Culture/Partials/CultureManagement/CultureManagement.vue'
  )
const CultureCode = () =>
  import(
    /* webpackChunkName: "CulturePage" */
    '@/pages/EngagementPage/Tabs/Culture/Partials/CultureCode/CultureCode.vue'
  )

const ParticipationPage = () =>
  import(
    /* webpackChunkName: "ParticipationPage" */
    '@/pages/EngagementPage/Tabs/Participation/ParticipationPage.vue'
  )

const ComparisonPage = () =>
  import(
    /* webpackChunkName: "ComparisonPage" */
    '@/pages/EngagementPage/Tabs/Comparison/ComparisonPage.vue'
  )

const BenchmarkPage = () =>
  import(
    /* webpackChunkName: "BenchmarkPage" */
    '@/pages/EngagementPage/Tabs/Benchmark/BenchmarkPage.vue'
  )
const IndicatorsPage = () =>
  import(
    /* webpackChunkName: "BenchmarkPage" */
    '@/pages/EngagementPage/Tabs/Benchmark/Tabs/Indicators/IndicatorsPage.vue'
  )
const RankingPage = () =>
  import(
    /* webpackChunkName: "BenchmarkPage" */
    '@/pages/EngagementPage/Tabs/Benchmark/Tabs/Ranking/RankingPage.vue'
  )
const InternalRankingPage = () =>
  import(
    /* webpackChunkName: "BenchmarkPage" */
    '@/pages/EngagementPage/Tabs/Benchmark/Tabs/InternalRanking/InternalRankingPage.vue'
  )

const OpinionsPage = () =>
  import(
    /* webpackChunkName: "OpinionsPage" */
    '@/pages/EngagementPage/Tabs/Opinions/OpinionsPage.vue'
  )
const ReceivedOpinionsPage = () =>
  import(
    /* webpackChunkName: "OpinionsPage" */
    '@/pages/EngagementPage/Tabs/Opinions/Tabs/ReceivedOpinions/ReceivedOpinionsPage.vue'
  )
const AnalyticsReportPage = () =>
  import(
    /* webpackChunkName: "OpinionsPage" */
    '@/pages/EngagementPage/Tabs/Opinions/Tabs/AnalyticsReport/AnalyticsReportPage.vue'
  )

const ActionPlanPage = () =>
  import(
    /* webpackChunkName: "ActionPlanPage" */
    '@/pages/EngagementPage/Tabs/ActionPlan/ActionPlanPage.vue'
  )
const ActionPlanAnalyticsReportPage = () =>
  import(
    /* webpackChunkName: "ActionPlanPage" */
    '@/pages/EngagementPage/Tabs/ActionPlan/Tabs/AnalyticsReport/AnalyticsReportPage.vue'
  )
const ActionPlansPage = () =>
  import(
    /* webpackChunkName: "ActionPlanPage" */
    '@/pages/EngagementPage/Tabs/ActionPlan/Tabs/ActionPlans/ActionPlansPage.vue'
  )

const SurveyPage = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/SurveyPage.vue'
  )
const SurveyInit = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/Partials/SurveyInit/SurveyInit.vue'
  )
const SurveySteps = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/Partials/SurveySteps/SurveySteps.vue'
  )
const SurveyFeedBack = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/Partials/SurveyFeedback/SurveyFeedback.vue'
  )
const SurveyFinish = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/Partials/SurveyFinish/SurveyFinish.vue'
  )
const SurveyForbidden = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/Partials/SurveyForbidden/SurveyForbidden.vue'
  )
const SurveyOther = () =>
  import(
    /* webpackChunkName: "SurveyPage" */
    '@/pages/SurveyPage/Partials/SurveyOther/SurveyOther.vue'
  )
const MyOpinions = () =>
  import(
    /* webpackChunkName: "MyOpinions" */
    '@/pages/EngagementPage/Tabs/Opinions/Tabs/MyOpinions/MyOpinions.vue'
  )
const MyOpinionsSentPage = () =>
  import(
    /* webpackChunkName: "MyOpinions" */
    '@/pages/EngagementPage/Tabs/Opinions/Tabs/MyOpinions/Children/Sent/Sent.vue'
  )
const MyOpinionsListPage = () =>
  import(
    /* webpackChunkName: "MyOpinions" */
    '@/pages/EngagementPage/Tabs/Opinions/Tabs/MyOpinions/Children/List/List.vue'
  )

import * as _permissions from '@/helpers/ability/permissions'

const routePage = (route) => {
  const { page = 1 } = route.params

  return {
    page: Math.max(1, Number(page)),
  }
}

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'EngagementPage' },
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },

  {
    path: '/',
    name: 'EngagementPage',
    component: EngagementPage,

    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.engagement,
    },

    children: [
      {
        path: 'climate',
        name: 'ClimatePage',
        component: ClimatePage,

        meta: {
          requiresPermission: _permissions.engagement_climate,
          title: 'engagementPage.navigation.climate',
        },
      },
      {
        path: 'culture',
        name: 'CulturePage',
        component: CulturePage,

        meta: {
          requiresPermission: _permissions.engagement_culture,
          title: 'engagementPage.navigation.culture',
        },

        children: [
          {
            path: 'management',
            name: 'cultureManagement',
            component: CultureManagement,

            children: [
              {
                path: 'add-culture-code',
                name: 'AddCultureCode',
                component: CultureCode,

                meta: {
                  requiresPermission: _permissions.engagement_culture_creator,
                },
              },
              {
                path: 'edit-culture-code/:cultureId',
                name: 'EditCultureCode',
                component: CultureCode,
              },
            ],
          },
        ],
      },
      {
        path: 'participation',
        name: 'ParticipationPage',
        component: ParticipationPage,

        meta: {
          requiresPermission: _permissions.engagement_participations,
          title: 'engagementPage.navigation.participation',
        },
      },
      {
        path: 'comparison',
        name: 'ComparisonPage',
        component: ComparisonPage,

        meta: {
          requiresPermission: _permissions.engagement_groups_comparation,
          title: 'engagementPage.navigation.comparison',
        },
      },
      {
        path: 'benchmark',
        name: 'BenchmarkPage',
        component: BenchmarkPage,

        redirect: { name: 'IndicatorsPage' },
        meta: {
          requiresPermission: _permissions.engagement_benchmark,
          title: 'engagementPage.navigation.benchmark',
        },

        children: [
          {
            path: 'indicators',
            name: 'IndicatorsPage',
            component: IndicatorsPage,

            meta: {
              title: 'engagementPage.benchmark.navigation.indicators',
            },
          },
          {
            path: 'ranking',
            name: 'RankingPage',
            component: RankingPage,

            meta: {
              requiresPermission: _permissions.engagement_benchmark_ranking,
              title: 'engagementPage.benchmark.navigation.ranking',
            },
          },
          {
            path: 'ranking/internal',
            name: 'InternalRankingPage',
            component: InternalRankingPage,

            meta: {
              requiresPermission:
                _permissions.engagement_benchmark_internal_ranking,
              title: 'engagementPage.benchmark.navigation.internalRanking',
            },
          },
        ],
      },
      {
        path: 'opinions',
        name: 'OpinionsPage',
        component: OpinionsPage,
        redirect: { name: 'ReceivedOpinionsPage' },
        meta: {
          title: 'engagementPage.navigation.opinions',
        },

        children: [
          {
            path: 'me',
            name: 'MyOpinions',
            redirect: { name: 'SentOpinionsPage' },
            component: MyOpinions,
            children: [
              {
                path: 'list/:page?',
                name: 'ListOpinionsPage',
                component: MyOpinionsListPage,
                props: routePage,
              },
              {
                path: ':opinionID?',
                name: 'SentOpinionsPage',
                component: MyOpinionsSentPage,
                props: true,
              },
            ],
            meta: {
              requiresPermission: _permissions.profile_opinions,
              title: 'engagementPage.opinions.navigation.myOpinions',
            },
          },
          {
            path: 'received-opinions/list',
            name: 'ReceivedOpinionsListPage',
            component: ReceivedOpinionsPage,
            props: {
              listView: true,
            },
            meta: {
              requiresPermission: _permissions.engagement_opinions,
              title: 'engagementPage.opinions.navigation.receivedOpinions',
            },
          },
          {
            path: 'received-opinions/:selectedOpinionId?',
            name: 'ReceivedOpinionsPage',
            component: ReceivedOpinionsPage,
            props: true,
            meta: {
              requiresPermission: _permissions.engagement_opinions,
              title: 'engagementPage.opinions.navigation.receivedOpinions',
            },
          },
          {
            path: 'analytics-report',
            name: 'OpinionsAnalyticsReportPage',
            component: AnalyticsReportPage,

            meta: {
              requiresPermission: [
                _permissions.engagement_opinions,
                _permissions.engagement_opinions_reports,
              ],
              title: 'engagementPage.opinions.navigation.analyticsReport',
            },
          },
        ],
      },
      {
        path: 'action-plan',
        name: 'ActionPlanPage',
        component: ActionPlanPage,

        redirect: { name: 'ActionPlansPage' },
        meta: {
          requiresPermission: _permissions.engagement_action_plans,
          title: 'engagementPage.actionPlan.navigation.actionPlans',
        },

        children: [
          {
            path: 'action-plans',
            name: 'ActionPlansPage',
            component: ActionPlansPage,
          },
          {
            path: 'analytics-report',
            name: 'ActionPlanAnalyticsReportPage',
            component: ActionPlanAnalyticsReportPage,

            meta: {
              requiresPermission: _permissions.engagement_action_plans_reports,
              title: 'engagementPage.actionPlan.navigation.analyticsReport',
            },
          },
        ],
      },
    ],
  },

  {
    path: '/survey',
    component: SurveyPage,

    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.engagement_survey,
      title: 'engagementPage.navigation.survey',
    },

    children: [
      {
        path: '',
        name: 'SurveyInit',
        component: SurveyInit,
      },
      {
        path: 'steps',
        name: 'SurveySteps',
        component: SurveySteps,
      },
      {
        path: 'feedback',
        name: 'SurveyFeedback',
        component: SurveyFeedBack,
      },
      {
        path: 'finish',
        name: 'SurveyFinish',
        component: SurveyFinish,
      },
      {
        path: 'forbidden',
        name: 'SurveyForbidden',
        component: SurveyForbidden,
      },
      {
        path: 'other',
        name: 'SurveyOther',
        component: SurveyOther,
      },
    ],
  },
]
