<template>
  <v-dialog v-model="show" @click:outside="closeDialog" max-width="834px">
    <v-card class="rounded-lg overall-average">
      <div class="title-dialog">
        <h3>{{ $t('benchmarkBadge.dialogAverageTitle') }}</h3>
        <v-btn
          id="close-benchmark-ranking"
          icon
          @click="closeDialog"
          class="white mr-3"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="img-dialog">
        <img width="100%" :src="_rankImgSrc" :alt="_imgAltText" />
      </div>
      <div class="text-dialog">
        {{ $t('benchmarkBadge.dialogAverageText') }}
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { calcLanguageToLocale } from '@/helpers/locale'

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    imgAlt: String,
  },
  data() {
    return {
      show: this.isOpen,
    }
  },
  computed: {
    _imgAltText() {
      if (this.imgAlt) {
        return this.imgAlt
      }

      return this.$t('benchmarkBadge.dialogBenchmarkRankImgAlt')
    },
    _rankImgSrc() {
      const images = {
        br: () => require('@/assets/images/DialogBenchmarkRank/dialog-br.png'),
        en: () => require('@/assets/images/DialogBenchmarkRank/dialog-en.png'),
        es: () => require('@/assets/images/DialogBenchmarkRank/dialog-es.png'),
      }

      const lang = calcLanguageToLocale(this.$i18n.locale)

      return images[lang]()
    },
  },
  methods: {
    closeDialog() {
      this.open = false
      this.$emit('update:isOpen')
    },
  },
  watch: {
    isOpen(newValue) {
      this.show = newValue
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
