import api from '@/service/Http'
import { constructParams, extractSignal } from '@/helpers/api'

/**
 * Retorna a listagem de opiniões
 * @param {Number} limit Número máximo de itens para retornar
 * @param {Number} offset Número de itens para pular antes de retornar os resultados
 * @param {String} metricID Código único do pilar
 * @param {String} groupID Código único do grupo
 * @param {String} questionID Código único da pergunta
 * @param {Boolean} reply
 * @param {Boolean} readed
 * @param {Boolean} closed
 * @param {Boolean} rated
 * @param {String} feeling
 * @param {String} fromDate Última mensagem desde
 * @param {String} toDate Última mensagem até
 * @returns {Array<Object>}
 */
export async function getOpinions(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'sortOrder',
      'groupID',
      'metricID',
      'questionID',
      'reply',
      'readed',
      'closed',
      'rated',
      'ratedScore',
      'feeling',
      'fromDate',
      'toDate',
      'tagsID',
      'sortBy',
      'period',
    ],
    data
  )

  return (await api()).get('/engagement/opinions', {
    params: params,
  })
}

/**
 * @type {OpinionAdvancedSummary}
 *
 * @property {object} opinions {
 *   @property {number} open
 *   @property {number} closed
 *   @property {number} answered
 *   @property {number} received
 *   @property {object} averageAnswerTime {
 *     @property {number} days
 *     @property {number} hours
 *   }
 *   @property {object} averageClosingTime {
 *     @property {number} days
 *     @property {number} hours
 *   }
 * }
 * @property {array} metrics {
 *   @property {string} id
 *   @property {string} label
 *   @property {number} positive
 *   @property {number} constructive
 * }
 * @property {array} ratings {
 *   @property {string} id
 *   @property {number} value
 * }
 * @property {array} tags {
 *   @property {string} id
 *   @property {string} label
 *   @property {number} value
 * }
 */

export async function getEngagementOpinionsAdvancedSummary(groupID) {
  const params = {}

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/opinions/advanced-summary', {
    params,
  })
}

/**
 * @type {OpinionSummaryHistory}
 *
 * @property {String[]} dates
 * @property {Number[]} opinionsReceived
 * @property {Number[]} opinionsAnswered
 */

export async function getEngagementOpinionsAdvancedSummaryHistory(groupID) {
  const params = {}

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/opinions/advanced-summary/history', {
    params,
  })
}

/**
 * @type {OpinionSummaryVariation}
 *
 * @property {Number} received
 * @property {Number} answered
 * @property {object} averageAnswerTime {
 *   @property {Number} days
 *   @property {Number} hours
 * }
 * @property {object} averageClosingTime {
 *   @property {Number} days
 *   @property {Number} hours
 * }
 */

export async function getEngagementOpinionsAdvancedSummaryVariation(
  groupID,
  period = 'real-time'
) {
  const params = {
    period,
  }

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/opinions/advanced-summary/variation', {
    params,
  })
}

export async function getEngagementOpinionsSummary(
  module = 'climate',
  groupID
) {
  const params = {
    module,
  }

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get(`/engagement/opinions/summary`, {
    params,
  })
}

/**
 * Cadastrar Tag de Opiniões
 *
 * @param {String} label Nome da tag
 *
 * @returns {Object} {
 *  {String} id
 *  {String} label
 * }
 */
export async function createOpinionTag({ label }) {
  return (await api()).post('/engagement/opinions/tags', {
    label,
  })
}

/**
 * Exibir Tags de Opiniões
 *
 * @param {Number} limit Número máximo de itens para retornar
 * @param {Number} offset Número de itens para pular antes de retornar os resultados
 *
 * @returns {Array<Object>} {
 *  {String} id
 *  {String} label
 * }
 */
export async function getOpinionTags({ limit, offset }) {
  return (await api()).get('/engagement/opinions/tags', {
    params: {
      limit,
      offset,
    },
  })
}

/**
 * Adicionar uma tag a uma opinião
 *
 * @param {String} opinionID Código único da opinião
 * @param {String} tagID Código único da tag
 */
export async function addTagToOpinion({ opinionID, tagID }) {
  return (await api()).put(`/engagement/opinions/${opinionID}/tags/${tagID}`)
}

/**
 * Remover uma tag de uma opinião
 *
 * @param {String} opinionID Código único da opinião
 * @param {String} tagID Código único da tag
 */
export async function removeTagFromOpinion({ opinionID, tagID }) {
  return (await api()).delete(`/engagement/opinions/${opinionID}/tags/${tagID}`)
}

/**
 * Obter mensagens da opinião
 *
 * @param {String} opinionID Código único da opinião
 */
export async function getOpinionInfo({ groupID, opinionID }) {
  const params = {}

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get(`/engagement/opinions/${opinionID}`, { params })
}

/**
 * Marcar a Opinião Visualizada
 *
 * @param {String} opinionID Código único da opinião
 */
export async function markOpinionAsReaded({ opinionID }) {
  return (await api()).put(`/engagement/opinions/${opinionID}/reading`)
}

/**
 * Marcar a Opinião como Não Visualizada
 *
 * @param {String} opinionID Código único da opinião
 */
export async function markOpinionAsUnread({ opinionID }) {
  return (await api()).delete(`/engagement/opinions/${opinionID}/reading`)
}

/**
 * Concluir opinião
 *
 * @param {String} opinionID Código único da opinião
 */
export async function closeOpinion({ opinionID }) {
  return (await api()).put(`/engagement/opinions/${opinionID}/closing`)
}

/**
 * Obter mensagens da opinião
 *
 * @param {String} opinionID Código único da opinião
 */
export async function getOpinionMessages({ opinionID }) {
  return (await api()).get(`/engagement/opinions/${opinionID}/messages`)
}

/**
 * Enviar resposta da opinião
 *
 * @param {String} opinionID Código único da opinião
 * @param {String} message Mensagem
 * @param {Boolean} isAnonymous Se a mensagem é anônima
 */
export async function sendOpinionMessage({ opinionID, message, isAnonymous }) {
  return (await api()).post(`/engagement/opinions/${opinionID}/messages`, {
    message,
    isAnonymous,
  })
}
/**
 * @typedef {Object} Variation
 * @property {Number} confirmed
 * @property {Number} confirmedRespondersRate
 * @property {Number} invited
 * @property {Number} invitedRespondersRate
 * @property {Number} responders
 */

/**
 * Retorna a variação do contexto de participação
 * @param {String} groupID Código único do grupo
 * @param {String} period Período da variação
 * @returns {Variation}
 */
export async function getVariation({ groupID = null, period = 'real-time' }) {
  const params = { period }
  if (groupID) {
    params.groupID = groupID
  }
  return (await api()).get('/engagement/opinions/advanced-summary/variation', {
    params,
  })
}

/**
 * Exporta as Opiniões
 * @param {Object} payload
 * @param {String} payload.groupID Código único do grupo
 * @returns {Blob}
 */
export async function exportOpinions(data) {
  const payload = constructParams(
    [
      'groupID',
      'metricID',
      'questionID',
      'reply',
      'readed',
      'closed',
      'rated',
      'ratedScore',
      'feeling',
      'fromDate',
      'toDate',
      'tagsID',
      'period',
    ],
    data
  )

  return (await api()).post('/engagement/opinions/export', payload)
}

export async function postOpinions(data) {
  const params = constructParams(['message', 'isAnonymous', 'isPositive'], data)

  return (await api()).post('/engagement/opinions', params)
}

export async function getMyOpinions(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'readed'], data)

  return (await api()).get('/engagement/opinions/me', {
    params: params,
    ...signal,
  })
}

export async function getOpinionDetail(data) {
  const signal = extractSignal(data)

  return (await api()).get('/engagement/opinions/:opinionID', {
    _params: {
      opinionID: data.opinionID,
    },
    ...signal,
  })
}

export async function getMeOpinionMessages(data) {
  const signal = extractSignal(data)

  return (await api()).get('/engagement/opinions/:opinionID/messages', {
    _params: {
      opinionID: data.opinionID,
    },
    ...signal,
  })
}

export async function putOpinionReading(data) {
  const signal = extractSignal(data)

  return (await api()).put(
    '/engagement/opinions/:opinionID/reading',
    {},
    {
      _params: {
        opinionID: data.opinionID,
      },
      ...signal,
    }
  )
}

export async function deleteOpinionReading(data) {
  return (await api()).delete('/engagement/opinions/:opinionID/reading', {
    _params: {
      opinionID: data.opinionID,
    },
  })
}

export async function putOpinionRating(data) {
  return (await api()).put(
    '/engagement/opinions/:opinionID/rating/:ratingID',
    {},
    {
      _params: {
        opinionID: data.opinionID,
        ratingID: data.ratingID,
      },
    }
  )
}

export async function postOpinionMessage(data) {
  return (await api()).post(
    '/engagement/opinions/:opinionID/messages',
    {
      message: data.message,
      isAnonymous: data.isAnonymous,
    },
    {
      _params: {
        opinionID: data.opinionID,
      },
    }
  )
}

export async function getOpinionClosestGroups(opinionID) {
  return (await api()).get('/engagement/opinions/:opinionID/closest-group', {
    _params: {
      opinionID,
    },
  })
}
