const randomColors = [
  '#a288d1',
  '#aaa85b',
  '#d26d98',
  '#3d9a2c',
  '#713733',
  '#87bb29',
  '#c78d97',
  '#dd3f29',
  '#508c73',
  '#eb4d14',
  '#5691a5',
  '#cf6a2e',
  '#397043',
  '#ae4ded',
  '#c69a6d',
  '#512198',
  '#476023',
  '#d73d85',
  '#3f4222',
  '#6c38cd',
  '#aa8b6e',
  '#766beb',
  '#92ab6b',
  '#56327e',
  '#cdb623',
  '#67344e',
  '#4acd2c',
  '#b785a6',
  '#50cc59',
  '#48486c',
  '#d03e3b',
  '#6e9acb',
  '#873519',
  '#49bcb1',
  '#d9764c',
  '#43bcd8',
  '#da745e',
  '#6481e3',
  '#8aa679',
  '#5854bb',
  '#cb8675',
  '#d640d2',
  '#745127',
  '#d542ac',
  '#938d2c',
  '#45538c',
  '#e28723',
  '#ab8bba',
  '#a6681a',
  '#7c2b65',
  '#88b144',
  '#882541',
  '#5abc68',
  '#c770bc',
  '#76641f',
  '#b664d3',
  '#49be8a',
  '#dc3562',
  '#5694da',
  '#d5a24e',
  '#d37581',
  '#bea732',
  '#bd5155',
  '#508633',
  '#c1884d',
]
export default randomColors
