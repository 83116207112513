/**
 * UpdateYourPackage
 * @typedef {'action' | 'attributes' | 'benchmark' | 'pillar' | 'recommendation' | 'segmentation' | 'variation'} UpdatePackage
 */

/**
 * @type {import('vuex').Module}
 */
export const UpdateYourPackage = {
  namespaced: false,

  state: () => ({
    UpdateYourPackage: {
      opened: false,

      /**
       * @type {UpdatePackage}
       */
      type: 'action',
    },
  }),

  getters: {
    getUpdateYourPackageStatus: ({ UpdateYourPackage }) => {
      return UpdateYourPackage.opened
    },
    getUpdateYourPackageType: ({ UpdateYourPackage }) => {
      return UpdateYourPackage.type
    },
  },

  actions: {},

  mutations: {
    closeUpdateYourPackageDialog(state) {
      state.UpdateYourPackage.opened = false
    },

    openUpdateYourPackageDialog(state, type) {
      state.UpdateYourPackage.opened = true
      state.UpdateYourPackage.type = type
    },
  },
}
