import { BenchmarkRank } from './BenchmarkRank'
import { ExplanationNPS } from './ExplanationNPS'
import { UpdateYourPackage } from './UpdateYourPackage'

/**
 * @type {import('vuex').Module}
 */
export const DialogModule = {
  namespaced: false,

  state: () => ({
    ...BenchmarkRank.state(),
    ...ExplanationNPS.state(),
    ...UpdateYourPackage.state(),
  }),

  getters: {
    ...BenchmarkRank.getters,
    ...ExplanationNPS.getters,
    ...UpdateYourPackage.getters,
  },

  actions: {
    ...BenchmarkRank.actions,
    ...ExplanationNPS.actions,
    ...UpdateYourPackage.actions,
  },

  mutations: {
    ...BenchmarkRank.mutations,
    ...ExplanationNPS.mutations,
    ...UpdateYourPackage.mutations,
  },
}
