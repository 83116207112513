/**
 * @type {import('vuex').Module}
 */
export const ManagementCulture = {
  state: () => ({
    ManagementCulture: {
      opened: false,
    },
  }),

  getters: {
    getShowCultureManagement: ({ ManagementCulture }) => {
      return ManagementCulture.opened
    },
  },

  actions: {},

  mutations: {
    toggleShowCultureManagement(state, payload) {
      state.ManagementCulture.opened = payload
    },
  },
}
