<template>
  <div class="loading-wrapper">
    <v-progress-circular
      color="primary"
      :size="50"
      indeterminate
    ></v-progress-circular>

    <div class="loading-container">
      <p class="loading-text" v-text="$t('LoginPage.loading.text')"></p>
      <span
        class="loading-description"
        v-text="$t('LoginPage.loading.description')"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingParcel',
}
</script>

<style src="./style.scss" lang="scss" scoped />
