<template>
  <SideBar v-on="$listeners" :haveFooter="false">
    <template v-slot:title>
      {{ $t('actionPlan.sideBar.titleTemplate') }}
    </template>

    <template v-slot:body>
      <section>
        <div class="content-action">
          <div class="d-flex align-center">
            <v-text-field
              v-model="search"
              :label="$t('actionPlan.sideBar.template.search')"
              class="custom-search"
              hide-details="auto"
              solo
              flat
              dense
              @keyup="searchTemplate()"
            >
              <template v-slot:prepend>
                <v-icon class="mt-1 ml-3" color="neutral6" small>
                  fi fi-rr-search
                </v-icon>
              </template>
            </v-text-field>

            <v-spacer />

            <v-btn
              v-if="displayNewPlan"
              data-test-btn-new-plan
              class="text-none"
              color="primary"
              dark
              depressed
              @click="$emit('new-plan')"
            >
              <v-icon left>fi-rr-plus-small</v-icon>

              <span
                class="bttn-label"
                v-text="$t('actionPlan.sideBar.newPlanBttn')"
              ></span>
            </v-btn>
          </div>
        </div>

        <div class="content-section-header">
          <span
            class="content-section-header-title"
            v-text="$t('actionPlan.sideBar.template.title')"
          ></span>
        </div>

        <!-- loader -->
        <div v-if="loading" data-test-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="table-tbody"
            :types="{ 'table-row': 'table-cell@4' }"
          />
        </div>

        <!-- table -->
        <div
          v-else-if="search.length > 0 || templates.length > 0"
          class="flex-column"
        >
          <v-data-table
            class="custom-table"
            height="100%"
            :headers="headers"
            :items="templates"
            :no-data-text="$t('actionPlan.sideBar.form.noData')"
            hide-default-footer
            disable-pagination
            disable-sort
            data-test-action-plan-template-list
            data-test-template-list-table
          >
            <!-- title -->
            <template v-slot:[`item.title`]="{ item }">
              <span class="custom-table-item-title" v-text="item.title"></span>
            </template>

            <!-- description -->
            <template v-slot:[`item.description`]="{ item }">
              <span
                class="custom-table-item-subtitle"
                v-text="item.description"
              ></span>
            </template>

            <!-- type -->
            <template v-slot:[`item.origin`]="{ item }">
              <span
                class="custom-table-item-title"
                v-text="handleOrigin(item.origin)"
              ></span>
            </template>

            <!-- actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  color="neutral5"
                  small
                  icon
                  data-test-template-action-plan
                  @click="openTemplate(item.id)"
                >
                  <v-icon small> fi-rr-circle</v-icon>
                  <v-icon class="absolute" x-small
                    >fi-sr-arrow-small-right</v-icon
                  >
                </v-btn>

                <v-btn
                  v-if="item.origin === 'account'"
                  color="neutral5"
                  small
                  icon
                  @click="deleteTemplate(item.id)"
                >
                  <v-icon small>fi-rr-trash</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>

          <!-- pagination -->
          <div class="d-flex justify-center align-center py-3">
            <v-btn class="mr-1" tile icon x-small @click="prev()">
              <v-icon x-small>fi fi-br-angle-left</v-icon>
            </v-btn>

            <v-sheet
              color="grayColor2"
              height="18"
              width="18"
              class="d-flex align-center justify-center fill-height rounded-md"
            >
              <span class="label-pagination" v-text="pagination.page"></span>
            </v-sheet>

            <v-btn class="ml-1" tile icon x-small @click="next()">
              <v-icon x-small>fi fi-br-angle-right</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- empty -->
        <div v-else data-test-empty-template class="text-center py-2">
          <span
            class="message"
            v-text="$t('actionPlan.sideBar.template.empty.message')"
          ></span>

          <br />

          <a
            class="message-link"
            href="#"
            v-text="$t('actionPlan.sideBar.template.empty.messageLink')"
          ></a>
        </div>
      </section>
    </template>
  </SideBar>
</template>

<script>
import SideBar from '@/components/SideBar/SideBar.vue'

export default {
  name: 'TemplateList',
  components: {
    SideBar,
  },

  props: {
    templates: [],
    displayNewPlan: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      interval: null,
      search: '',
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
      },
      headers: [
        {
          text: this.$t('actionPlan.sideBar.template.table.title'),
          value: 'title',
          width: '30%',
        },
        {
          text: this.$t('actionPlan.sideBar.template.table.description'),
          value: 'description',
          width: '40%',
        },
        {
          text: this.$t('actionPlan.sideBar.template.table.origin'),
          value: 'origin',
        },
        {
          text: this.$t('actionPlan.sideBar.template.table.actions'),
          value: 'actions',
        },
      ],
    }
  },

  mounted() {
    this.getTemplates()
  },

  methods: {
    // open template
    openTemplate(data) {
      this.$emit('open-template', data)
    },

    // get templates
    async getTemplates() {
      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        title: this.search,
      }

      this.$emit('get-templates', payload)
    },

    // search template
    searchTemplate() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.getTemplates()
      }, 500)
    },

    // delete template
    async deleteTemplate(id) {
      this.loading = true
      this.$emit('delete-template', id)
    },

    // prev table page
    prev() {
      if (this.pagination.page === 1) return

      this.pagination.page--
      this.pagination.offset = this.pagination.offset - this.pagination.limit
      this.getTemplates()
    },

    // next table page
    next() {
      if (this.templates.length < this.pagination.limit) return

      this.pagination.page++
      this.pagination.offset = this.pagination.offset + this.pagination.limit
      this.getTemplates()
    },

    handleOrigin(value) {
      switch (value) {
        case 'account':
          return this.$t('actionPlan.sideBar.template.origin.account')
        case 'teamculture':
          return this.$t('actionPlan.sideBar.template.origin.teamculture')
      }
    },

    stopLoad() {
      if (this.loading) this.loading = false
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
