import api from '@/service/Http'
import { constructParams } from '@/helpers/api'

/**
 * Lista de planos de ação
 *  *
 * @param {Number} limit Número máximo de itens
 * @param {Number} offset Número de itens para pular antes de retornar os resultados
 * @param {String} groupID Código único do grupo
 */
export async function getActionsPlans({
  groupID,
  limit,
  offset,
  sortOrder = 'asc',
  sortBy = 'title',
  title,
  personID = null,
  indicatorID = null,
  type = null,
  status = null,
  fromDate = null,
  toDate = null,
}) {
  return (await api()).get('/engagement/action-plans', {
    params: {
      groupID,
      limit,
      offset,
      sortBy,
      sortOrder,
      title,
      personID,
      indicatorID,
      type,
      status,
      fromDate,
      toDate,
    },
  })
}

export async function getActionPlan(actionPlanID) {
  return (await api()).get(`/engagement/action-plans/${actionPlanID}`)
}

/**
 * @type {ActionPlanSummaryHistory}
 *
 * @property {Array<Number>} total
 * @property {Array<Number>} closed
 * @property {Array<Number>} delayed
 * @property {Array<String>} dates
 */

/**
 *  Retorna o histórico do resumo de planos de ação
 * @param {String} groupID Código único do grupo
 * @returns {ActionPlanSummaryHistory}
 */
export async function getActionPlanSummaryHistory(groupID) {
  return (await api()).get('/engagement/action-plans/summary/history', {
    params: { groupID },
  })
}

/**
 * @type {ActionPlanSummary}
 *
 * @property {Number} total
 * @property {Number} closed
 * @property {Number} delayed
 * @property {Number} open
 * @property {Number} successClosed
 * @property {Number} delayedClosed
 * @property {Number} averageActionPlansClosingTime
 * @property {Number} averageTasksClosingTime
 * @property {SummaryTypes} types
 */

/**
 * @type {SummaryTypes}
 *
 * @property {SummaryType} climate
 * @property {SummaryType} culture
 * @property {SummaryType} participation
 */

/**
 * @type {SummaryType}
 *
 * @property {Number} open
 * @property {Number} closed
 * @property {Number} delayed
 */

/**
 *  Retorna o resumo de planos de ação
 * @param {String} groupID Código único do grupo
 * @returns {ActionPlanSummary}
 */
export async function getActionPlanSummary(groupID) {
  return (await api()).get('/engagement/action-plans/summary', {
    params: { groupID },
  })
}

/*
 * Quantidade de tarefas totais, abertas e concluídas por responsáveis
 *
 * @param {String} groupID ID do grupo
 * @param {Number} limit Número máximo de itens para retornar
 * @param {Number} offset Número de itens para pular antes de retornar os resultados
 */
export async function getActionPlansSummaryAssignees({
  groupID,
  limit,
  offset,
  sortBy,
  sortOrder,
}) {
  return (await api()).get('/engagement/action-plans/summary/assignees', {
    params: {
      groupID,
      sortBy,
      sortOrder,
      limit,
      offset,
    },
  })
}

export const newPlan = async (data) =>
  (await api()).post('/engagement/action-plans', data)

export const getPlan = async (data) =>
  (await api()).get(`/engagement/action-plans/${data}`)

export const editPlan = async (data) =>
  (await api()).put(`/engagement/action-plans/${data.id}`, data.body)

export const deletePlan = async (id) =>
  (await api()).delete(`/engagement/action-plans/${id}`)

export const saveTemplate = async (data) =>
  (await api()).post('/engagement/action-plans/templates', data)

export const deleteTemplate = async (data) =>
  (await api()).delete(`/engagement/action-plans/templates/${data}`)

export const getTemplates = async (data) => {
  let endpoint = `/engagement/action-plans/templates?limit=${data.limit}&offset=${data.offset}`
  if (data.title) endpoint += `&title=${data.title}`

  return (await api()).get(endpoint)
}

export const getTemplateById = async (data) =>
  (await api()).get(`/engagement/action-plans/templates/${data}`)

export const getIndicators = async (data) => {
  let endpoint = `/engagement/action-plans/indicators?type=${data.type}`
  if (data.groupID) endpoint += `&groupID=${data.groupID}`

  return (await api()).get(endpoint)
}

export const getTasks = async (data) =>
  (await api()).get(`/engagement/action-plans/${data}/tasks`)

export const newTask = async (data) =>
  (await api()).post(
    `/engagement/action-plans/${data.actionPlanID}/tasks`,
    data.body
  )

export const editTask = async (data) =>
  (await api()).put(
    `/engagement/action-plans/${data.actionPlanID}/tasks/${data.taskID}`,
    data.body
  )

export const deleteTask = async (data) =>
  (await api()).delete(
    `/engagement/action-plans/${data.actionPlanID}/tasks/${data.taskID}`
  )

/**
 * @typedef {Object} Group
 *
 * @property {String} id
 * @property {String} name
 */

/**
 * @typedef {Object} Metric
 *
 * @property {String} id
 * @property {String} label
 */

/**
 * @typedef {Object} Progress
 *
 * @property {Number} current
 * @property {Number} goal
 * @property {Number} initial
 */

/**
 * @typedef {Object} Tasks
 *
 * @property {Number} completed
 * @property {Number} total
 */

/**
 * @typedef {Object} ActionPlansResult
 *
 * @property {String} dueDate
 * @property {String} id
 * @property {Group} group
 * @property {Metric} metric
 * @property {Progress} progress
 * @property {String} status
 * @property {Tasks} tasks
 * @property {String} title
 * @property {String} type
 */

/**
 * @param {Object} param
 * @param {String} param.groupID Group unique code
 * @param {String} param.type Action plan type
 * @param {Number} param.limit Results limiter
 * @param {Number} param.offset Position where should start looking for results
 * @param {String} param.status Action plan status
 * @returns {Array.<ActionPlansResult>} List of action plans
 */
export async function listActionPlans({
  groupID,
  type,
  limit = 20,
  offset = 0,
  status = 'doing',
  sortBy = 'created-date',
  sortOrder = 'desc',
}) {
  const params = {}
  if (groupID) {
    params.groupID = groupID
  }

  if (type) {
    params.type = type
  }

  if (limit) {
    params.limit = limit
  }

  if (offset >= 0) {
    params.offset = offset
  }

  if (status) {
    params.status = status
  }

  if (sortBy) {
    params.sortBy = sortBy
  }

  if (sortOrder) {
    params.sortOrder = sortOrder
  }

  return (await api()).get('/engagement/action-plans', {
    params,
  })
}

/**
 * @typedef {Object} ActionPlanCreator
 * @property {String} id
 * @property {String} name
 */

/**
 *  Returns action plan creators
 * @param {String} groupID group unique code
 * @returns {Array<ActionPlanCreator>} action plan creators
 */
export async function listActionPlanCreators(payload) {
  const params = constructParams(['groupID'], payload)

  return (await api()).get('/engagement/action-plans/creators', {
    params,
  })
}

/**
 * @typedef {Object} variation
 * @property {Number} total
 * @property {Number} opened
 * @property {Number} closed
 * @property {Number} expired
 * @property {Number} completeActionPlans
 * @property {Number} completeTasks
 */

/**
 * @param {string} params.period variation period (required)
 * @param {string} params.groupID
 *
 * @returns {variation} variation object
 */
export const getVariation = async ({ period, groupID = null }) => {
  const params = { period }

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/action-plans/summary/variation', {
    params,
  })
}
