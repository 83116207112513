<template>
  <LateralMenuItem
    :title="$t('demographicReports.smartAttributes.title')"
    :isMini="isMini"
    howToType="sidebarSmartAttributes"
    :showCrown="locked"
  >
    <template v-slot:title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>
    <div
      data-test-smart-attributes-blocked
      class="blocked"
      v-if="locked"
      @click="callUpdateYourPackage()"
    >
      <div class="glass"></div>
      <div class="blocked-icon">
        <i class="fi fi-sr-lock"></i>
      </div>
    </div>
    <form
      v-if="!isLoading"
      @submit.prevent="generateReport"
      class="smart-attributes"
      data-test-smart-attributes-form
    >
      <ItemSelector
        v-for="attribute in _getAttributes"
        :data-test-smart-attribute="attribute.label"
        :key="attribute.id"
        :inputConfig="{
          label: attribute.label,
          showAvatar: false,
          clearable: true,
        }"
        :inputStyle="'submitMode'"
        :menuConfig="{
          showTabs: false,
        }"
        :menuOptions="[
          {
            value: attribute.id,
            label: attribute.label,
            type: 'listview',
            items: attribute.values,
          },
        ]"
        :currentValue="{
          data: selectedValues[attribute.id],
          origin: attribute.id,
        }"
        :localSearch="true"
        :watchCurrent="true"
        :fillWidth="true"
        :multiple="true"
        :readonly="locked"
        @update:item="handleSelectAttribute(attribute.id, $event)"
      ></ItemSelector>

      <v-btn
        type="submit"
        data-test-generate-report
        text
        color="primary"
        retain-focus-on-click
      >
        <v-icon size="10" color="white">mdi-poll</v-icon>
        <span>{{ $t('demographicReports.smartAttributes.generate') }}</span>
      </v-btn>
    </form>
    <v-row v-if="isLoading && !locked">
      <v-col cols="12">
        <v-skeleton-loader type="list-item" height="30"></v-skeleton-loader>
        <v-skeleton-loader type="list-item" height="30"></v-skeleton-loader>
        <v-skeleton-loader type="list-item" height="30"></v-skeleton-loader>
      </v-col>
    </v-row>
  </LateralMenuItem>
</template>

<script>
export default {
  name: 'SmartAttributes',
  props: {
    isMini: Boolean,
    attributes: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    locked: Boolean,
  },
  data() {
    return {
      selectedValues: {},
    }
  },
  computed: {
    _getAttributes() {
      if (this.locked) {
        const mockAttribute = { id: 'id', label: 'Atributo', values: [] }
        const mockList = [mockAttribute, mockAttribute, mockAttribute]

        return mockList
      }

      return this.attributes
    },
  },
  methods: {
    callUpdateYourPackage() {
      this.$store.commit('openUpdateYourPackageDialog', 'attributes')
    },
    generateReport() {
      const payload = Object.entries(this.selectedValues)
        .flatMap(([key, value]) => value?.map((v) => ({ key, value: v?.id })))
        .filter((v) => v.value)

      if (payload.length === 0) {
        alert(this.$t('demographicReports.selectAsmartAttribue'))
        return
      }

      this.$emit('on-generate-report', payload)
    },
    clearForm() {
      const resetedValues = (this.attributes || []).reduce((acc, attribute) => {
        acc[attribute.id] = []
        return acc
      }, {})

      this.$set(this, 'selectedValues', resetedValues)
    },
    handleSelectAttribute(attributeID, value) {
      this.$set(this.selectedValues, attributeID, value || [])
    },
  },
  watch: {
    attributes: {
      handler() {
        this.clearForm()
      },
      immediate: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
