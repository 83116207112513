import Vue from 'vue'

import {
  getRecommendationApproval,
  getRecommendationDetails,
  voteRecommendation,
} from '@/service/recommendations'

/**
 * @type {import('vuex').Module}
 */
export const RecommendationsMoreDetails = {
  state: () => ({
    RecommendationsMoreDetails: {
      opened: false,

      recommendationId: null,
      props: {
        approvalDetails: null,
        sections: {
          id: null,
          title: null,
          image: null,
          what: {},
          how: {},
          benefit: {},
        },
      },
    },
  }),

  getters: {
    RecommendationsMoreDetails__Status: ({ RecommendationsMoreDetails }) => {
      return RecommendationsMoreDetails.opened
    },
    RecommendationsMoreDetails__Props: ({ RecommendationsMoreDetails }) => {
      return {
        recommendationId: RecommendationsMoreDetails.recommendationId,
        ...RecommendationsMoreDetails.props,
      }
    },
  },

  actions: {
    async voteRecommendationsMoreDetails({ state, commit }, payload) {
      const { id, vote } = payload

      const approvalDetails =
        state.RecommendationsMoreDetails.props.approvalDetails

      await voteRecommendation(id, {
        approval: approvalDetails !== vote ? vote : null,
      }).then(({ data }) => {
        commit('SET_RecommendationsMoreDetails__approvalDetails', data.approval)
      })
    },

    async openRecommendationsMoreDetails({ commit }, recommendationId) {
      let approvalDetails = null
      await getRecommendationApproval(recommendationId).then(({ data }) => {
        approvalDetails = data.approval
      })

      let sections = null
      await getRecommendationDetails(recommendationId).then(({ data }) => {
        sections = data
      })

      if (!sections) {
        return new Promise.reject()
      }

      commit('SET_RecommendationsMoreDetails__Props', {
        sections,
        approvalDetails,
      })

      commit('SET_RecommendationsMoreDetails__openend', {
        opened: true,
        recommendationId,
      })
    },

    closeRecommendationsMoreDetails({ commit }) {
      commit('SET_RecommendationsMoreDetails__openend', {
        opened: false,
        recommendationId: null,
      })

      commit('SET_RecommendationsMoreDetails__Props', {
        approvalDetails: null,
        sections: {
          id: null,
          title: null,
          image: null,
          what: {},
          how: {},
          benefit: {},
        },
      })
    },
  },

  mutations: {
    SET_RecommendationsMoreDetails__openend(state, payload) {
      state.RecommendationsMoreDetails.opened = payload.opened
      state.RecommendationsMoreDetails.recommendationId =
        payload.recommendationId
    },

    SET_RecommendationsMoreDetails__Props(state, payload) {
      Vue.set(state.RecommendationsMoreDetails, 'props', payload)
    },

    SET_RecommendationsMoreDetails__approvalDetails(state, payload) {
      Vue.set(
        state.RecommendationsMoreDetails.props,
        'approvalDetails',
        payload
      )
    },
  },
}
