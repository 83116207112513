<template>
  <LateralMenuItem
    :title="$t('demographicReports.title')"
    :isMini="isMini"
    :showCrown="locked"
  >
    <template v-slot:title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>
    <template v-slot:header-action>
      <v-btn class="no-padding no-margin" icon small @click="handleRefresh">
        <i class="fi-rr-refresh refresh"></i>
      </v-btn>
    </template>

    <div
      data-test-demographic-reports-blocked
      class="blocked"
      v-if="locked"
      @click="callUpdateYourPackage()"
    >
      <div class="glass"></div>
      <div class="blocked-icon">
        <i class="fi fi-sr-lock"></i>
      </div>
    </div>

    <div data-test-reports-container>
      <div
        v-for="(report, index) in _getReports"
        :key="report.id"
        class="report-row"
        :data-test-demographic-report="report.status"
      >
        <p
          @click="() => onReportClick(report.id, report.status)"
          :class="{
            'status-text-done': report.status === 'done',
            'status-text-processing': report.status === 'processing',
            'report-selected': report.id === reportSelected,
          }"
        >
          <i :class="getIconByReportStatus(report.status)"></i>
          {{ getReportName(report, index) }}
        </p>
        <v-btn icon small @click="() => handleDelete(report.id)">
          <v-icon x-small v-text="'fi-rr-trash'" color="neutral5" />
        </v-btn>
      </div>
      <v-row class="no-margin">
        <v-col
          v-if="_isEmpty && !isLoading && !locked"
          class="empty-demographic-reports"
          data-test-empty-demographic-reports
        >
          <p>
            {{ $t('demographicReports.emptyMessage') }}
          </p>
        </v-col>
        <v-col
          v-if="displayShowMoreAction && !isLoading"
          class="show-more-column"
        >
          <div
            class="show-more-container"
            @click="handleShowMore"
            data-test-show-more-reports
          >
            {{ $t('showMore') }}
            <i class="fi fi-sr-caret-down"></i>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="isLoading && !locked" class="loading-container">
      <v-skeleton-loader
        type="list-item-two-line"
        height="55"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="list-item-two-line"
        height="55"
      ></v-skeleton-loader>
    </div>
  </LateralMenuItem>
</template>

<script>
import moment from 'moment'
import { calcLanguageToLocale } from '@/helpers/locale'

export default {
  name: 'DemographicReports',
  props: {
    isMini: Boolean,
    reports: { type: Array, default: () => [] },
    isLoading: Boolean,
    locked: Boolean,
    displayShowMoreAction: Boolean,
    reportSelected: null,
  },

  created() {
    const lang = calcLanguageToLocale(this.$i18n.locale)
    moment.locale(lang)
  },
  computed: {
    _isEmpty() {
      return this.reports.length === 0
    },
    _getReports() {
      if (this.locked) {
        const mockReport = {
          attributes: [
            { key: 'Cargo', value: 'Coordenador' },
            { key: 'Cargo', value: 'Coordenador' },
            { key: 'Cargo', value: 'Coordenador' },
            { key: 'Cargo', value: 'Coordenador' },
          ],
          date: '2021-12-01T20:26:40.015Z',
          id: '61b34c0b7170e500293e2ec7',
          key: '61981937ed6fcf00287a18a7|61a99317faa15c00288d25d9:61a99317faa15c00288d25d6',
          status: 'done',
        }

        const mockList = [mockReport, mockReport]

        return mockList
      }

      return this.reports
    },
  },
  methods: {
    getReportName(report, position) {
      let attributesString = ''

      report.attributes.forEach((attribute, index) => {
        const isTheLastItem = report.attributes.length - 1 === index
        const separator = isTheLastItem ? '' : '|'

        attributesString += `${attribute.key}: ${attribute.value} ${separator} `
      })

      const creationDate = moment(report.date).format(
        this.$t('commonDateFormat')
      )

      return `#${position + 1} - ${creationDate} - ${attributesString}`
    },
    getIconByReportStatus(status) {
      return status === 'done'
        ? 'fi-rr-file-check status-icon-done'
        : 'fi-rr-time-quarter-to status-icon-processing'
    },
    callUpdateYourPackage() {
      this.$store.commit('openUpdateYourPackageDialog', 'attributes')
    },
    handleShowMore() {
      this.$emit('on-show-more-click', { showMore: true })
    },
    onReportClick(id, status) {
      if (status !== 'done') return

      let idToEmit = id

      if (this.reportSelected === id) {
        idToEmit = ''
      }

      this.$root.$emit('app:click:demographic-report', idToEmit)
      this.$emit('on-report-click', { id: idToEmit, unselect: !idToEmit })
    },
    handleDelete(id) {
      this.$emit('on-delete-click', id)
    },
    handleRefresh() {
      this.$emit('on-refresh-click', { reset: true })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
