import variables from '@/styles/abstracts/_colors.module.scss'

import i18n from '@/plugins/i18n'
import router from '@/router'

import * as _canKey from '@/helpers/ability/permissions'

export function genSiteNavigationItens(onClickLogout = () => {}) {
  const CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

  return {
    userLinks: {
      logout: {
        click: onClickLogout,
        text: i18n.t('ui-components.siteNavigation.user.logout'),
      },
      help: {
        url: i18n.t('ui-components.siteNavigation.helpInfoLink'),
        text: i18n.t('ui-components.siteNavigation.help'),
      },
      settings: {
        url: new URL(
          'users/profile',
          process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK
        ).toString(),
        text: i18n.t('ui-components.siteNavigation.user.settings'),
      },
    },
    menuLinks: [
      {
        name: 'Profile',
        url: '/profile',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/home.svg`,
          title: i18n.t('ui-components.siteNavigation.Home'),
          color: variables.yellowColor8,
        },
        permissioned: _canKey.profile,
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.profile'),
      },
      {
        name: 'Engagement',
        url: null,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/engagement-active.svg`,
          title: i18n.t('ui-components.siteNavigation.Engagement'),
          color: variables.redColor11,
          current: true,
        },
        customEvents: {
          click: () => {
            const navigation = { name: 'EngagementPage' }
            if (router.currentRoute.name !== navigation.name) {
              router.push(navigation)
            }
          },
        },
        permissioned: [
          _canKey.engagement_climate,
          _canKey.engagement_culture,
          _canKey.engagement_participations,
          _canKey.engagement_groups_comparation,
          _canKey.engagement_benchmark,
          _canKey.profile_opinions,
          _canKey.engagement_opinions,
          _canKey.engagement_action_plans,
        ],
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.engagement'),
      },
      {
        name: 'Performance',
        url: process.env.VUE_APP_PERFORMANCE_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/performance.svg`,
          title: i18n.t('ui-components.siteNavigation.Performance'),
          color: variables.blueColor8,
        },
        permissioned: _canKey.performance,
        ariaLabel: i18n.t('navBar.accessibility.performance'),
      },
      {
        name: 'Growth',
        url: '/growth',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/growth.svg`,
          title: i18n.t('ui-components.siteNavigation.Growth'),
          color: variables.greenColor12,
        },
        permissioned: [
          _canKey.growth,
          _canKey.profile_growth,
          _canKey.profile_feedback,
        ],
        tabindex: '1',
        ariaLabel: i18n.t('navBar.accessibility.growth'),
      },
      {
        name: 'Surveys',
        url: process.env.VUE_APP_CUSTOM_SURVEY_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/advanced-survery.svg`,
          title: i18n.t('ui-components.siteNavigation.Survery'),
          color: variables.pinkColor4,
        },
        permissioned: _canKey.customSurvey,
        newLayout: {
          url: '/custom-survey',
          permissioned: _canKey.profile_home_custom_survey,
        },
      },
      {
        name: 'Recognition',
        url: '/rewards',
        image: {
          src: `${CDN_BASE_URL}/images/navigation/recognition.svg`,
          title: i18n.t('ui-components.siteNavigation.Rewards'),
          color: variables.orangeColor4,
        },
        permissioned: _canKey.rewards,
        tabindex: '-1',
      },
      {
        name: 'Management',
        url: process.env.VUE_APP_MANAGEMENT_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/management.svg`,
          title: i18n.t('ui-components.siteNavigation.Management'),
          color: variables.grayColor12,
        },
        permissioned: _canKey.management,
        ariaLabel: i18n.t('navBar.accessibility.management'),
      },
    ],
  }
}

export function genPageNavigationItens() {
  const routes = [
    {
      path: '/climate',
      name: 'ClimatePage',
      text: i18n.t('engagementPage.navigation.climate'),
      permissioned: _canKey.engagement_climate,
    },
    {
      path: '/culture',
      name: 'CulturePage',
      text: i18n.t('engagementPage.navigation.culture'),
      permissioned: _canKey.engagement_culture,
      upgrade: 'culture',
    },
    {
      path: '/participation',
      name: 'ParticipationPage',
      text: i18n.t('engagementPage.navigation.participation'),
      permissioned: _canKey.engagement_participations,
    },
    {
      path: '/comparison',
      name: 'ComparisonPage',
      text: i18n.t('engagementPage.navigation.comparison'),
      permissioned: _canKey.engagement_groups_comparation,
      upgrade: 'variation',
    },
    {
      path: '/benchmark',
      name: 'BenchmarkPage',
      text: i18n.t('engagementPage.navigation.benchmark'),
      permissioned: _canKey.engagement_benchmark,
      upgrade: 'benchmark',

      children: [
        {
          path: '/indicators',
          name: 'IndicatorsPage',
          text: i18n.t('engagementPage.benchmark.navigation.indicators'),
          permissioned: _canKey.engagement_benchmark,

          sideAction: {
            name: 'openBenchmarkRanking',
            text: i18n.t('engagementPage.benchmark.navigation.rankingScale'),
          },
        },
        {
          path: '/ranking',
          name: 'RankingPage',
          text: i18n.t('engagementPage.benchmark.navigation.ranking'),
          permissioned: _canKey.engagement_benchmark_ranking,

          sideAction: {
            path: '/internal',
            name: 'InternalRankingPage',
            icon: 'fi-br-stats',
            text: i18n.t('engagementPage.benchmark.navigation.internalRanking'),
          },
        },
      ],
    },
    {
      path: '/opinions',
      name: 'OpinionsPage',
      text: i18n.t('engagementPage.navigation.opinions'),

      children: [
        {
          path: '/me',
          name: 'MyOpinions',
          text: i18n.t('engagementPage.opinions.navigation.myOpinions'),
          permissioned: _canKey.profile_opinions,
          sideAction: {
            path: '/list',
            name: 'ListOpinionsPage',
            icon: 'fi-br-interlining',
            text: i18n.t('engagementPage.opinions.navigation.listViewTitle'),
          },
        },
        {
          path: '/received-opinions',
          name: 'ReceivedOpinionsPage',
          text: i18n.t('engagementPage.opinions.navigation.receivedOpinions'),
          icon: 'fi-rr-users-alt',
          permissioned: _canKey.engagement_opinions,
          helpCenter: {
            videoType: 'opinions.general',
            hasVideo: false,
          },

          sideAction: {
            path: '/list',
            name: 'ReceivedOpinionsListPage',
            icon: 'fi-br-interlining',
            text: i18n.t('engagementPage.opinions.navigation.listViewTitle'),
          },
        },
        {
          path: '/analytics-report',
          name: 'OpinionsAnalyticsReportPage',
          text: i18n.t('engagementPage.opinions.navigation.analyticsReport'),
          icon: 'fi-rr-users-alt',
          permissioned: _canKey.engagement_opinions_reports,
          upgrade: 'analytics',
          helpCenter: {
            videoType: 'opinions.analytics',
            hasVideo: false,
          },
        },
      ],
    },
    {
      path: '/action-plan',
      name: 'ActionPlanPage',
      text: i18n.t('engagementPage.navigation.actionPlan'),
      permissioned: _canKey.engagement_action_plans,
      upgrade: 'action',

      children: [
        {
          path: '/action-plans',
          name: 'ActionPlansPage',
          text: i18n.t('engagementPage.actionPlan.navigation.actionPlans'),
          icon: 'fi-rr-users-alt',
          permissioned: _canKey.engagement_action_plans,
          helpCenter: {
            videoType: 'actionPlan.general',
            hasVideo: false,
          },
        },
        {
          path: '/analytics-report',
          name: 'ActionPlanAnalyticsReportPage',
          text: i18n.t('engagementPage.actionPlan.navigation.analyticsReport'),
          icon: 'fi-rr-users-alt',
          permissioned: _canKey.engagement_action_plans_reports,
          helpCenter: {
            videoType: 'actionPlan.analytics',
            hasVideo: false,
          },
        },
      ],
    },
  ]

  return routes
}
