<template>
  <v-form v-model="valid" ref="form" v-on:submit.prevent>
    <div
      class="new-task"
      :class="{
        active:
          ($refs.owners ? $refs.owners.menuDrop : false) ||
          datePick ||
          !!form.text ||
          !!form.dueDate ||
          !!form.assignedTo.length,
      }"
      data-test-new-task
    >
      <v-text-field
        data-test-new-task-name
        v-model="form.text"
        class="new-task-input"
        color="black"
        hide-details="auto"
        :label="$t('actionPlan.sideBar.form.task.newTask.text')"
        outlined
        dense
        @keypress.enter.prevent="submit()"
      />

      <div class="new-task-actions">
        <!-- dueDate -->
        <v-menu
          v-model="datePick"
          ref="menu"
          attach
          offset-x
          offset-y
          left
          top
          :close-on-content-click="false"
          :return-value.sync="form.dueDate"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip top :disabled="!form.dueDate">
              <template
                v-slot:activator="{ attrs: attrsTooltip, on: onTooltip }"
              >
                <v-btn
                  data-test-new-task-duedate-btn
                  v-bind="{ ...attrs, ...attrsTooltip }"
                  v-on="{ ...on, ...onTooltip }"
                  class="date-picker-btn"
                  :class="{ active: !!form.dueDate, 'error-btn': !validDate }"
                  color="neutral6"
                  icon
                  outlined
                  width="26"
                  height="26"
                >
                  <v-icon size="12">fi fi-rr-calendar</v-icon>
                </v-btn>
              </template>

              <div class="text-tooltip">
                <span>{{ formatDate(form.dueDate) }}</span>
              </div>
            </v-tooltip>
          </template>

          <v-date-picker
            data-test-new-task-duedate-picker
            v-model="form.dueDate"
            next-icon="fi-br-angle-small-right"
            prev-icon="fi-br-angle-small-left"
            :min="(dueDateHasMinDate && minDate) || ''"
            :locale="$t('locale')"
            color="primary"
            scrollable
            no-title
            @input="handleInputDate($event)"
          >
            <v-spacer />

            <v-btn text color="primary" @click="datePick = false">
              {{ $t('actionPlan.sideBar.form.cancel') }}
            </v-btn>

            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(form.dueDate)"
              data-test-new-task-duedate-confirm-btn
            >
              {{ $t('actionPlan.sideBar.form.ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>

        <!-- peoples -->
        <ItemSelector
          ref="owners"
          :menuProps="{
            offsetX: true,
            left: true,
            top: true,
          }"
          :menuConfig="{
            attach: true,
            showInsiderSearch: true,
          }"
          :inputConfig="{
            label: $t('dataTable.search', {
              title: $t('newNode.details.selectors.people'),
            }),
          }"
          :currentValue="{
            data: form.assignedTo || [],
            origin: 'peoples',
          }"
          :watchCurrent="true"
          :multiple="multiplePeople"
          :menuOptions="ownersOptions"
          :loading="ownersLoading"
          @focus:input="selectorOwnersHandle"
          @search:item="selectorOwnersHandle($event)"
          @update:item="form.assignedTo = [...$event].filter(Boolean)"
        >
          <template #default="{ focus, selectedItem }">
            <button
              data-test-new-task-peoples-btn
              v-ripple
              class="selector-add-peoples"
              :class="{
                'with-values':
                  selectedItem &&
                  selectedItem.data &&
                  selectedItem.data.length > 0,
                'error-btn': !validPeople,
              }"
              @click="focus"
            >
              <AvatarGroup
                v-if="
                  selectedItem &&
                  selectedItem.data &&
                  selectedItem.data.length > 0
                "
                :avatars="
                  selectedItem.data.map((e) => ({
                    id: e.id,
                    text: e.label,
                    image: e.image,
                  }))
                "
                :max="2"
                :size="26"
              />
              <v-icon size="12" v-else>fi-rr-user-add</v-icon>
            </button>
          </template>
        </ItemSelector>

        <!-- confirm -->
        <v-btn
          data-test-new-task-confirm
          color="primary"
          class="save-btn"
          depressed
          small
          @click="submit()"
        >
          <span class="bttn-label">
            {{ $t('actionPlan.sideBar.form.task.newTask.save') }}
          </span>
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import { getPeople } from '@/service/people'

import { v4 as uuidv4 } from 'uuid'
const moment = require('moment')

export default {
  name: 'NewTask',
  data() {
    return {
      valid: false,
      validDate: true,
      validPeople: true,

      datePick: false,
      minDate: new Date().toISOString().slice(0, 10),
      auxDate: '',

      form: {
        _id: uuidv4(),
        text: '',
        dueDate: null,
        assignedTo: [],
      },

      ownersLoading: true,
      ownersItens: [],
    }
  },

  props: {
    actionPlanID: null,
    editMode: {
      type: Boolean,
      default: false,
    },
    dueDateHasMinDate: {
      type: Boolean,
      default: false,
    },
    multiplePeople: {
      type: Boolean,
      default: false,
    },
    mandatoryDate: {
      type: Boolean,
      default: false,
    },
    mandatoryPeople: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ownersOptions() {
      return [
        {
          value: 'peoples',
          label: this.$t('newNode.details.selectors.people'),
          type: 'listview',
          items: this.ownersItens,
        },
      ]
    },
  },

  methods: {
    submit() {
      if (!this.form.text || this.form.text === '') return
      if (!this.$refs.form.validate()) return
      if (this.validate()) return

      this.form.dueDate = this.form.dueDate != '' ? this.form.dueDate : null

      if (!this.editMode) this.newTaskCreate()
      else this.newTaskEdit()

      this.resetForm()
    },

    validate() {
      if (this.mandatoryDate) {
        this.validDate = !!this.form.dueDate
      }

      if (this.mandatoryPeople) {
        this.validPeople = !!this.form.assignedTo.length
      }

      return !this.validDate || !this.validPeople
    },

    newTaskCreate() {
      if (!this.$refs.form.validate()) return

      this.form.dueDate = this.form.dueDate != '' ? this.form.dueDate : null

      const payload = {
        ...this.form,
        status: 'pending',
      }

      this.$emit('success-create', payload)
    },

    async newTaskEdit() {
      if (!this.$refs.form.validate()) return

      this.form.dueDate = this.form.dueDate != '' ? this.form.dueDate : null

      const payload = {
        actionPlanID: this.actionPlanID,
        body: {
          ...Object.keys(this.form)
            .filter((key) => key !== '_id')
            .reduce((obj, key) => {
              obj[key] = this.form[key]
              return obj
            }, {}),

          assignedTo: this.form.assignedTo.map((e) => ({ id: e.id })),
          status: 'pending',
        },
      }

      this.$emit('new-task-edit', payload)
    },

    handleInputDate(value) {
      if (value !== this.auxDate) this.auxDate = value
      else {
        this.auxDate = ''
        this.form.dueDate = null
      }
    },

    resetForm() {
      this.form = {
        _id: uuidv4(),
        text: '',
        dueDate: null,
        assignedTo: [],
      }
      this.auxDate = ''

      this.validDate = true
      this.validPeople = true
      this.$refs.form.reset()
    },

    formatDate(date) {
      if (!date) return null

      return moment(date).format(this.$t('actionPlan.sideBar.form.dateFormat'))
    },

    // Selectors Getters
    async selectorOwnersHandle(search = '') {
      this.ownersLoading = true

      const payload = {
        limit: 20,
        offset: 0,
        name: search || '',
      }

      await getPeople(payload).then((res) => {
        this.ownersItens = res.data.map((e) => {
          return {
            id: e.id,
            label: e.name,
            image: e.photo,
            email: e.email,
            groups: e.groups?.map((e) => e.name),
          }
        })
      })

      this.ownersLoading = false
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
