import api from '@/service/Http'

export const getFavoriteFilters = async (params) =>
  (await api()).get(`/favorite-filters`, {
    params: {
      limit: params.limit || 20,
      offset: params.offset || 0,
      multi: params.multi,
    },
  })

export const addFavoriteFilters = async (payload) =>
  (await api()).post(`/favorite-filters`, payload)

export const saveFavoriteFilters = async (id, payload) =>
  (await api()).put(`/favorite-filters/${id}`, payload)

export const removeFavoriteFilters = async (id) =>
  (await api()).delete(`/favorite-filters/${id}`)
