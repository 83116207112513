/**
 * @type {import('vuex').Module}
 */
export const ExplanationNPS = {
  namespaced: false,

  state: () => ({
    ExplanationNPS: {
      opened: false,

      type: 'lnps',
    },
  }),

  getters: {
    getExplanationNPS_Status: ({ ExplanationNPS }) => {
      return ExplanationNPS.opened
    },
    getExplanationNPSType: ({ ExplanationNPS }) => {
      return ExplanationNPS.type
    },
  },

  actions: {},

  mutations: {
    closeExplanationNPSDialog(state) {
      state.ExplanationNPS.opened = false
    },
    openExplanationNPSDialog(state, type) {
      state.ExplanationNPS.opened = true
      state.ExplanationNPS.type = type
    },
  },
}
