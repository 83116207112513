<template>
  <div id="sidebar" data-test-side-bar>
    <div class="glass" @click="close()" />

    <div class="content" data-test-sidebar-content>
      <!-- header -->
      <div v-if="haveHeader" class="content-header">
        <v-btn
          data-test-side-bar-back-btn
          color="neutral5"
          class="text-none back-bttn"
          text
          @click.prevent="back()"
        >
          <v-icon class="mr-2" small>fi fi-rr-arrow-left</v-icon>

          <span class="content-header-label">
            {{ $t('actionPlan.sideBar.back') }}
          </span>
        </v-btn>

        <v-spacer />

        <span class="content-header-title" data-test-side-bar-title>
          <!-- title slot -->
          <slot name="title" />
        </span>

        <v-spacer />

        <span
          v-if="autoSaveLabel"
          class="auto-save-label mr-1"
          v-text="$t('cultureCode.header.autoSave')"
        ></span>

        <v-btn
          data-test-close-sidebar
          color="neutral5"
          class="close-bttn"
          icon
          @click.prevent="close()"
        >
          <v-icon x-small>fi fi-rr-cross</v-icon>
        </v-btn>
      </div>

      <!-- body -->
      <slot name="body" />

      <!-- footer -->
      <div v-if="haveFooter" class="d-flex content-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    haveHeader: {
      type: Boolean,
      default: true,
    },

    haveFooter: {
      type: Boolean,
      default: true,
    },

    autoSaveLabel: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    this.handleBlur()
  },

  beforeDestroy() {
    this.handleBlur()
  },

  methods: {
    back() {
      this.$emit('back')
    },

    close() {
      this.$emit('close')
    },

    handleBlur() {
      this.$root.$emit('handle-blur')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
