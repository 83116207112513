/**
 * @type {import('vuex').Module}
 */
export const BenchmarkRank = {
  namespaced: false,

  state: () => ({
    BenchmarkRank: {
      opened: false,
    },
  }),

  getters: {
    getOverallAverageStatus: ({ BenchmarkRank }) => {
      return BenchmarkRank.opened
    },
  },

  actions: {},

  mutations: {
    handleBenchmarkRankingDialog(state) {
      const toggleValue = !state.BenchmarkRank.opened
      state.BenchmarkRank.opened = toggleValue
    },
  },
}
