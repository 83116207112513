import { projectAuth } from '@/firebase/config'

const calcRouteQuery = ({ l = null, t = null, ...toQuery }) => {
  return {
    loginQuery: {
      ...(l && { l }),
      ...(t && { t }),
    },
    toQuery,
  }
}

export const beforeEachRouterEntry = async (to, from, next) => {
  const currentUser = projectAuth.currentUser
  const requiresAuth = to.matched.some((record) => record?.meta?.requiresAuth)

  if (requiresAuth && !currentUser && to.name !== 'Login') {
    const { loginQuery, toQuery } = calcRouteQuery(to.query)

    if (!to.path.includes('/login')) {
      sessionStorage.setItem(
        'interface-recovery--redirectPath',
        JSON.stringify({ to, toQuery })
      )
    }

    if (from.name !== 'Login') {
      return next({
        name: 'Login',
        query: loginQuery,
      })
    }
  }

  if (!requiresAuth && currentUser) {
    return next({ name: 'Main' })
  }

  return next()
}

export default { beforeEachRouterEntry }
