<template>
  <div data-test-sidebar-group-people>
    <LateralMenuItem
      :title="$t('groupPeople.title')"
      :is-mini="isMini"
      how-to-type="sidebarGroupPeople"
    >
      <template #title-icon="{ size }">
        <v-icon :size="size">mdi-account-multiple</v-icon>
      </template>

      <section>
        <div v-if="groupPeopleApiController.itens.length" class="people-list">
          <div
            v-for="people in groupPeopleApiController.itens"
            :key="people.id"
            class="people-list-row"
            data-test-group-people-list-item
          >
            <AvatarComponent
              :id="people.id"
              :name="people.name"
              :photo="people.photo"
            ></AvatarComponent>

            <div>
              <p class="people-list-name">
                {{ people.name }}

                <span v-if="getRoleLabel(people.role)" class="people-list-role">
                  <v-icon size="0.3125rem">fi-ss-circle-small</v-icon>
                  <span v-text="getRoleLabel(people.role)"></span>
                </span>
              </p>

              <p class="people-list-email" v-text="people.email"></p>
            </div>
          </div>
        </div>

        <div
          v-if="
            !groupPeopleApiController.loading &&
            !groupPeopleApiController.itens.length
          "
          class="empty-group-people"
          data-test-empty-group-people
        >
          <p v-text="$t('groupPeople.empty')"></p>
        </div>

        <template v-if="!groupPeopleApiController.loading && _showMore">
          <v-btn
            data-test-show-more-group-people
            class="show-more-container"
            text
            @click="handleShowMore"
          >
            <span v-text="$t('groupPeople.showMore')"></span>
            <v-icon size="0.875rem">fi-sr-caret-down</v-icon>
          </v-btn>
        </template>

        <div
          v-if="groupPeopleApiController.loading"
          class="loading-group-people"
        >
          <v-skeleton-loader
            v-for="index in groupPeopleApiController.limit"
            :key="index"
            height="56"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </div>
      </section>
    </LateralMenuItem>
  </div>
</template>

<script>
import { handleApiRequestWithController } from '@/helpers/api'
import { listPeopleInTheGroup } from '@/service/groups'

export default {
  name: 'GroupPeople',
  props: {
    isMini: Boolean,
    groupID: String,
  },
  data() {
    return {
      groupPeopleApiController: {
        xCount: 0,
        limit: 5,
        offset: 0,
        loading: true,
        infinite: true,
        itens: [],
      },
    }
  },
  computed: {
    _showMore() {
      return (
        this.groupPeopleApiController.xCount >
        this.groupPeopleApiController.itens.length
      )
    },
  },
  watch: {
    groupID: {
      handler(value, oldValue) {
        if (value !== oldValue || !value) {
          this.groupPeopleApiController = {
            xCount: 0,
            limit: 5,
            offset: 0,
            loading: true,
            infinite: true,
            itens: [],
          }
        }

        this.listPeopleInTheGroup()
      },
      immediate: true,
    },
  },
  methods: {
    async listPeopleInTheGroup() {
      if (!this.groupID) return

      await handleApiRequestWithController(
        listPeopleInTheGroup,
        {
          groupID: this.groupID,
          limit: this.groupPeopleApiController.limit,
          offset: this.groupPeopleApiController.offset,
        },
        this.groupPeopleApiController,
        'id'
      ).then(({ xCount }) => {
        this.groupPeopleApiController.xCount = xCount
      })
    },

    getRoleLabel(role) {
      switch (role) {
        case 'manager':
          return this.$t('groupPeople.manager')
        case 'member':
          return this.$t('groupPeople.member')
        case 'follower':
          return this.$t('groupPeople.follower')
        default:
          return null
      }
    },

    handleShowMore() {
      this.listPeopleInTheGroup()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
