import { ActionPlans } from './ActionPlans'
import { ManagementCulture } from './ManagementCulture'
import { OpinionBox } from './OpinionBox'
import { RecommendationsMoreDetails } from './RecommendationsMoreDetails'

/**
 * @type {import('vuex').Module}
 */
export const SideModule = {
  namespaced: false,

  state: () => ({
    ...ActionPlans.state(),
    ...ManagementCulture.state(),
    ...OpinionBox.state(),
    ...RecommendationsMoreDetails.state(),
  }),

  getters: {
    ...ActionPlans.getters,
    ...ManagementCulture.getters,
    ...OpinionBox.getters,
    ...RecommendationsMoreDetails.getters,
  },

  actions: {
    ...ActionPlans.actions,
    ...ManagementCulture.actions,
    ...OpinionBox.actions,
    ...RecommendationsMoreDetails.actions,
  },

  mutations: {
    ...ActionPlans.mutations,
    ...ManagementCulture.mutations,
    ...OpinionBox.mutations,
    ...RecommendationsMoreDetails.mutations,
  },
}
