<template>
  <LateralMenuItem
    :title="$t('actionPlanFilter.title')"
    :isMini="isMini"
    data-test-sidebar-action-plan-filter
  >
    <template v-slot:title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <ComposeForm
      ref="ComposeForm"
      class="filter"
      :formName="filterName"
      :selectors="{ fields: _fields }"
      :storagePreferences="false"
      v-bind="ComposeFormProps()"
      @compose:values="setFiltersValue($event)"
    ></ComposeForm>
  </LateralMenuItem>
</template>

<script>
import {
  handleAxiosSignalAbortController,
  handleApiRequestWithController,
} from '@/helpers/api'

import { _permissions } from '@/helpers/ability/engagement'
import { listActionPlanCreators } from '@/service/actionPlans'

export default {
  name: 'ActionPlanFilter',

  props: {
    filterName: {
      type: String,
      required: true,
    },
    isMini: Boolean,

    groupID: {
      type: String,
    },
  },

  data() {
    return {
      form: {
        filter: {},
      },

      fields: [
        {
          id: 'status',
          _label: this.$t('actionPlanFilter.status'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'status',
              label: this.$t('actionPlanFilter.status'),
              type: 'listview',
              items: [
                {
                  id: 'doing',
                  label: this.$t('actionPlanFilter.statusFilter.doing'),
                },
                {
                  id: 'done',
                  label: this.$t('actionPlanFilter.statusFilter.done'),
                },
                {
                  id: 'delayed',
                  label: this.$t('actionPlanFilter.statusFilter.delayed'),
                },
              ],
            },
          ],
        },
        {
          id: 'type',
          _label: this.$t('actionPlanFilter.type'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'type',
              label: this.$t('actionPlanFilter.type'),
              type: 'listview',
              items: [
                {
                  id: 'generic',
                  label: this.$t('actionPlanFilter.typeFilter.generic'),
                },
                {
                  id: 'climate',
                  label: this.$t('actionPlanFilter.typeFilter.climate'),
                },
                {
                  id: 'culture',
                  label: this.$t('actionPlanFilter.typeFilter.culture'),
                },
                {
                  id: 'participation',
                  label: this.$t('actionPlanFilter.typeFilter.participation'),
                },
              ],
            },
          ],
        },
        {
          id: 'personID',
          _label: this.$t('actionPlanFilter.creator'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: true,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          eagerApi: true,
          api: {
            endpoint: listActionPlanCreators,
            searchKey: null,
            infinity: false,
            identifier: 'id',
            payload: {
              groupID: this.groupID,
            },
            hidrateFn: (item) => {
              return {
                ...item,
                label: item.name,
              }
            },
          },
        },
        {
          id: 'indicatorID',
          _label: this.$t('actionPlanFilter.metric'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'indicatorID',
              label: this.$t('actionPlanFilter.metric'),
              type: 'listview',
              items: [
                {
                  id: 'invitedRespondersRate',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.invitedRespondersRate'
                  ),
                },
                {
                  id: 'confirmedRespondersRate',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.confirmedRespondersRate'
                  ),
                },
                {
                  id: 'enps',
                  label: this.$t('actionPlanFilter.metricFilter.enps'),
                },
                {
                  id: 'lnps',
                  label: this.$t('actionPlanFilter.metricFilter.lnps'),
                },
                {
                  id: 'managerRelationship',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.managerRelationship'
                  ),
                },
                {
                  id: 'ambassadorship',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.ambassadorship'
                  ),
                },
                {
                  id: 'alignment',
                  label: this.$t('actionPlanFilter.metricFilter.alignment'),
                },
                {
                  id: 'wellness',
                  label: this.$t('actionPlanFilter.metricFilter.wellness'),
                },
                {
                  id: 'personalGrowth',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.personalGrowth'
                  ),
                },
                {
                  id: 'happiness',
                  label: this.$t('actionPlanFilter.metricFilter.happiness'),
                },
                {
                  id: 'satisfaction',
                  label: this.$t('actionPlanFilter.metricFilter.satisfaction'),
                },
                {
                  id: 'recognition',
                  label: this.$t('actionPlanFilter.metricFilter.recognition'),
                },
                {
                  id: 'peersRelationship',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.peersRelationship'
                  ),
                },
                {
                  id: 'feedback',
                  label: this.$t('actionPlanFilter.metricFilter.feedback'),
                },
                {
                  id: 'diversity',
                  label: this.$t('actionPlanFilter.metricFilter.diversity'),
                },
                {
                  id: 'psychologicalSafety',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.psychologicalSafety'
                  ),
                },
                {
                  id: 'burnout',
                  label: this.$t('actionPlanFilter.metricFilter.burnout'),
                },
                {
                  id: 'maturityLevel',
                  label: this.$t('actionPlanFilter.metricFilter.maturityLevel'),
                },
                {
                  id: 'culturalAlignment',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.culturalAlignment'
                  ),
                },
                {
                  id: 'unfavorableCulture',
                  label: this.$t(
                    'actionPlanFilter.metricFilter.unfavorableCulture'
                  ),
                },
              ],
            },
          ],
        },
      ],
    }
  },

  computed: {
    _fields() {
      if (!this._canEngagementActionPlans) {
        return this.fields.filter((field) => field.id !== 'personID')
      }

      return this.fields.map((field) => {
        if (field.id === 'personID') {
          return {
            ...field,
            api: {
              ...field.api,
              payload: {
                ...field.api.payload,
                groupID: this.groupID,
              },
            },
          }
        }

        return field
      })
    },
    _canEngagementActionPlans() {
      return this.$can('access', _permissions.engagement_action_plans)
    },
  },

  watch: {
    groupID() {
      this.$nextTick(() => {
        this.groupChange()
      })
    },
  },

  methods: {
    setFiltersValue(values) {
      this.$set(this.form, 'filter', values)
      this.sendFilterValues(values)
    },

    sendFilterValues(values) {
      let filters = structuredClone(values)

      Object.keys(filters).forEach((key) => {
        if (['status', 'type', 'personID', 'indicatorID'].includes(key)) {
          filters[key] = Array.isArray(filters[key])
            ? filters[key]?.[0]?.id
            : null
        }
      })

      this.$root.$emit('action-plans:filter-changes', filters)
    },

    groupChange() {
      if (this.$refs.ComposeForm) {
        this.$refs.ComposeForm.defineFieldAsDefault('personID')
        this.$refs.ComposeForm.getOptionsSearch('personID', null)
      }
    },

    ComposeFormProps() {
      return {
        handleAxiosSignalAbortController: handleAxiosSignalAbortController,
        handleApiRequestWithController: handleApiRequestWithController,
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
