import api from '@/service/Http'
import { constructParams } from '@/helpers/api'

export async function getPeople(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'name',
      'permissionGroupID',
      'directManagerID',
      'occupationID',
      'showInactive',
      'module',
    ],
    {
      ...data,
      module: 'engagement',
    }
  )

  return (await api()).get('/management/people', { params })
}

export async function getAttributesList(data) {
  const params = constructParams(['limit', 'offset', 'label'], data)

  return (await api()).get('/management/people/attributes', {
    params,
  })
}
