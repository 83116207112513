import api from '@/service/Http'

export async function getAccount() {
  return (await api()).get('/management/account')
}

export async function getAccountMe() {
  return (await api()).get('/management/account/me')
}

export async function putAccountMe(payload) {
  return (await api()).put('/management/account/me', payload)
}
