<template>
  <v-dialog
    attach
    v-model="open"
    width="52.125rem"
    content-class="update-your-package"
    overlay-color="transparent"
    overlay-opacity="1"
    @click:outside="closeDialog"
  >
    <v-card
      class="update-your-package-card"
      elevation="0"
      data-test-updateYourPackage
    >
      <div class="update-your-package-card-header">
        <div class="card-header-title">
          <h3 v-text="$t('updateYourPackage.title')"></h3>
          <v-icon class="upgrade-icon" size="0.875rem">fi-sr-crown</v-icon>
        </div>

        <v-btn
          data-test-close-update-your-package
          icon
          small
          class="card-header-close"
          @click="closeDialog"
        >
          <v-icon size="1.5rem">mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-container class="update-your-package-card-body">
        <div class="card-body-container">
          <div class="card-body-info">
            <div class="card-body-subtitle">
              <span
                v-text="$t('updateYourPackage.' + type + '.caption')"
              ></span>
              <v-icon class="upgrade-icon" size="0.875rem">
                fi-sr-crown
              </v-icon>
            </div>

            <div class="card-body-subheading">
              <p v-text="$t('updateYourPackage.' + type + '.subtitle')"></p>
            </div>
          </div>

          <div class="card-body-content">
            <p
              class="card-body-description"
              v-text="$t('updateYourPackage.body')"
            ></p>

            <ul class="card-body-list">
              <li v-for="(item, index) in _UpdateYourPackageList" :key="index">
                <v-icon size="0.875rem">fi-br-check</v-icon>
                <span v-text="$t(item)"></span>
              </li>
            </ul>
          </div>

          <div class="card-body-footer">
            <v-btn
              class="card-body-action-upgrade"
              @click="actionDialog()"
              elevation="0"
            >
              <span v-text="$t('updateYourPackage.button')"></span>
            </v-btn>
            <p
              class="card-body-action-price"
              v-text="$t('updateYourPackage.price')"
            ></p>
          </div>
        </div>

        <div class="card-body-container-showcase">
          <v-img
            contain
            :src="require(`@/assets/images/UpdateYourPlan/${type}.png`)"
            :alt="type"
          ></v-img>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateYourPackage',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },

    type: {
      type: String,
      required: true,
      validator: function (value) {
        return [
          'action',
          'attributes',
          'benchmark',
          'culture',
          'analytics',
          'pillar',
          'recommendation',
          'segmentation',
          'variation',
        ].includes(value)
      },
    },
  },
  data() {
    return {
      open: this.isOpen,
    }
  },

  computed: {
    _UpdateYourPackageList() {
      return this.$t(`updateYourPackage.list`)
    },
  },

  methods: {
    actionDialog() {
      this.$emit('dialog:action')
    },

    closeDialog() {
      this.open = false

      this.$emit('dialog:close')
    },
  },

  watch: {
    isOpen: {
      handler(newValue) {
        this.$set(this, 'open', newValue)
      },
      immediate: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
