import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'

import router from './router'
import store from './store'

import '@/plugins/casl'
import '@/plugins/mask'
import i18n from '@/plugins/i18n'

import App from './App.vue'
import '@/styles/main.scss'
import '@/styles/styles.scss'

import '@/plugins/ui-components'
import '@/plugins/treeselect'
import '@/plugins/apexchart'
import vuetify from '@/plugins/vuetify'
import unhead from '@/plugins/unhead'

Vue.config.productionTip = false
Vue.prototype.CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecycle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        },
      })
    },
    store,
    i18n,
    router,
    vuetify,
    unhead,
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
