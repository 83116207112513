import api from '@/service/Http'
import { constructParams } from '@/helpers/api'

/**
 * @type {ClimateResults}
 *
 * @property {String} id
 * @property {String} date
 * @property {Number} score
 * @property {Array<MetricsTree>} metrics
 * @property {Array<Metrics>} smartMetrics
 */

/**
 * Métricas dos pilares inteligentes
 * @type {Metrics}
 *
 * @property {String} metricID
 * @property {String} label
 * @property {String} image
 * @property {Number} score
 */

/**
 * Métricas com submétricas
 * @type {MetricsTree}
 *
 * @property {String} metricID
 * @property {String} label
 * @property {String} image
 * @property {Number} score
 * @property {Array<Submetric>} submetrics
 */

/**
 * Submétrica
 * @type {Submetric}
 *
 * @property {String} submetricID
 * @property {String} label
 * @property {Number} Score
 */

/**
 * Overall Engagement Score, 10 Pillars Score, and Smart Pillars Score
 *
 * @param {String} groupID Código único do grupo
 * @param {String} demographicReportID Identificador do relatório demográfico
 * @returns {ClimateResults}
 */
export async function getClimateResults(groupID, demographicReportID = null) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get('/engagement/climate/results', {
    params,
  })
}

/**
 * Histórico Simplificado
 * @type {SimpleHistory}
 *
 * @property {Array<Metrics>} metrics
 * @property {Array<String>} dates
 */

/**
 * Métricas
 * @type {Metrics}
 *
 * @property {String} id
 * @property {String} name
 * @property {Array<Number>} scores
 */

/**
 * Retorna o histórico simplificado de clima
 * @param {String} groupID Código único do grupo
 * @param {String} demographicReportID Identificador do relatório demográfico
 * @returns {SimpleHistory}
 */
export async function getClimateResultsHistory(
  groupID,
  demographicReportID = null
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get('/engagement/climate/results/history', {
    params,
  })
}

/**
 * Histórico Avançado
 * @type {AdvancedHistory}
 *
 * @property {Array<Metrics>} smartMetrics
 * @property {Array<Metrics>} metrics
 * @property {Array<String>} dates
 */

/**
 * Métricas
 * @type {Metrics}
 *
 * @property {String} id
 * @property {String} name
 * @property {Array<Number>} scores
 */

/**
 * Retorna o histórico avaçado de clima
 * @param {String} groupID Código único do grupo
 * @param {String} demographicReportID Identificador do relatório demográfico
 * @returns {AdvancedHistory}
 */
export async function getClimateResultsAdvancedHistory(
  groupID,
  demographicReportID = null,
  submetrics = null
) {
  const params = constructParams(
    ['groupID', 'demographicReportID', 'submetrics'],
    {
      groupID,
      demographicReportID,
      submetrics,
    }
  )

  return (await api()).get('/engagement/climate/results/advanced-history', {
    params,
  })
}

/**
 * Notas do ENPS ou LNPS
 * @type {NPS}
 *
 * @property {String} id
 * @property {String} date
 * @property {Number} detractors
 * @property {Number} passives
 * @property {Number} promoters
 * @property {Number} score
 * @property {Number} skiped

/**
 * Retorna as notas do ENPS ou LNPS
 * @param {String} groupID Código único do grupo
 * @returns {NPS}
 */
export async function getClimateNPSResults(
  npsType,
  groupID,
  demographicReportID = null
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get(`/engagement/climate/${npsType}/results`, {
    params,
  })
}

/**
 * Histórico de ENPS ou LNPS
 * @type {NPSHistory}
 *
 * @property {String} id
 * @property {String} date
 * @property {Number} detractors
 * @property {Number} passives
 * @property {Number} promoters
 * @property {Number} skiped
 * @property {Number} score
 *
 */

/**
 * Retorna o histórico de ENPS ou LNPS
 * @param {String} npsType enps ou lnps
 * @param {String} groupID Código único do grupo
 * @returns {Array<NPSHistory>}
 */
export async function getClimateNPSResultsHistory(
  npsType,
  groupID,
  demographicReportID = null
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get(`/engagement/climate/${npsType}/results/history`, {
    params,
  })
}

/**
 * Notas do LNPS
 * @type {Correlations}
 *
 * @property {String} label
 * @property {String} metricId
 * @property {Number} value
 * @property {Date} date
 * @property {String} id

/**
 * Obter a correlação entre os pilares de engajamento
 * @param {String} metricID Código único do pillar
 * @param {String} groupID Código único do grupo
 * @returns {Correlations}
 */
export async function getClimateCorrelations(metricID, groupID) {
  const params = {}
  if (groupID) {
    Object.assign(params, { groupID })
  }
  Object.assign(params, { metricID })
  return (await api()).get('/engagement/climate/correlation-results', {
    params,
  })
}

/**
 * Correlação dos pilares
 * @type {CorrelatedPillars}
 *
 * @property {String} id
 * @property {String} date
 * @property {Array<Correlation>} correlations
 *
 */

/**
 * Correlação
 * @type {Correlation}
 *
 * @property {String} metricID
 * @property {String} label
 * @property {Number} value
 */

/**
 * Retorna a correlação entre os pilares de engajamento
 * @param {String} metricID Código único do pilar
 * @param {String} groupID Código único do grupo
 * @returns {CorrelatedPillars}
 */
export async function getClimateCorrelationResults(metricID, groupID) {
  return (await api()).get(`/engagement/climate/correlation-results`, {
    params: { metricID, groupID },
  })
}

export async function getClimateAdvancedResultSmartMetrics(
  smartMetricID,
  groupID,
  demographicReportID = null
) {
  const params = constructParams(
    ['smartMetricID', 'groupID', 'demographicReportID'],
    {
      smartMetricID,
      groupID,
      demographicReportID,
    }
  )

  return (await api()).get(
    '/engagement/climate/advanced-results/smart-metrics',
    {
      params,
    }
  )
}

export async function getClimateAdvancedResultsMetrics(
  metricID,
  groupID,
  demographicReportID = null
) {
  const params = constructParams(
    ['metricID', 'groupID', 'demographicReportID'],
    {
      metricID,
      groupID,
      demographicReportID,
    }
  )

  return (await api()).get('/engagement/climate/advanced-results/metrics', {
    params,
  })
}

/**
 * @typedef {Object} VariationSubMetric
 * @property {String} submetricID
 * @property {Number} value
 */

/**
 * @typedef {Object} VariationMetric
 * @property {String} metricID
 * @property {Array<VariationSubMetric>} submetrics
 * @property {Number} value
 */

/**
 * @typedef {Object} VariationSmartMetric
 * @property {String} metricID
 * @property {Number} value
 */

/**
 * @typedef {Object} Variation
 * @property {Number} answeredOpinions
 * @property {Number} enps
 * @property {Number} lnps
 * @property {Array<VariationMetric>} metrics
 * @property {Number} participation
 * @property {Number} score
 * @property {Array<VariationSmartMetric>} smartMetrics
 */

/**
 * Retorna a variação para o contexto de clima
 * @param {String} period Período da variação
 * @param {String} groupID Código único do grupo
 * @returns {Variation} Variação para o contexto de clima
 */
export async function getVariation({ groupID = null, period = 'real-time' }) {
  const params = { period }
  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get(`/engagement/climate/variation`, { params })
}

export async function getQuestions(id = null) {
  const params = {}
  if (typeof id === 'string' && id) {
    params.id = id
  }

  return (await api()).get(`/engagement/climate/questions`, {
    params,
  })
}

/**
 * @typedef {Object} ReportAttribute
 * @property {String} key
 * @property {String} value
 */

/**
 * @typedef {Object} DemographicReport
 * @property {Array<ReportAttribute>} attributes
 * @property {String} date
 * @property {String} id
 * @property {String} key
 * @property {String} status
 */

/**
 * Retorna a lista de relatórios demográficos
 * @param {String} params.groupID identificador do grupo
 * @returns {Array<DemographicReport>}
 */
export async function getDemographicReportsList({
  groupID = null,
  limit = 20,
  offset = 0,
}) {
  const params = constructParams(['limit', 'offset', 'groupID'], {
    limit,
    offset,
    groupID,
  })

  return (await api()).get('/engagement/climate/demographic-reports', {
    params,
  })
}

export async function deleteDemographicReport(ID) {
  return (await api()).delete(`/engagement/climate/demographic-reports/${ID}`)
}

export async function createDemographicReport(payload) {
  const params = constructParams(['attributes', 'groupID'], payload)

  return (await api()).post(`/engagement/climate/demographic-reports`, params)
}

/**
 *
 * @param {Object} payload
 * @param {String} payload.type Tipo de relatório
 * @param {String} payload.groupID Código único do grupo
 * @returns {Blob}
 */
export async function exportClimate(payload) {
  return (await api()).post('/engagement/climate/results/export', payload, {
    responseType: 'blob',
  })
}
