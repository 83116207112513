import api from '@/service/Http'
import { constructParams } from '@/helpers/api'

export async function createCultureCode(data) {
  return (await api()).post('/engagement/culture/culture-codes', data)
}

/**
 * Histórico Simplificado de Cultura
 * @type {SimpleHistoryCulture}
 *
 * @property {Array<Metrics>} metrics
 * @property {Array<String>} dates
 */

/**
 * Métricas
 * @type {Metrics}
 *
 * @property {String} id
 * @property {String} name
 * @property {Array<Number>} scores
 */

/**
 * Retorna o histórico de engajamento simplificado
 * @param {String} groupID Código único do grupo
 * @returns {SimpleHistoryCulture}
 */
export async function getCultureResultsHistory(groupID) {
  return (await api()).get('/engagement/culture/results/history', {
    params: { groupID },
  })
}

export async function getCultureResults(data) {
  return (await api()).post('/engagement/culture/results', data)
}

/**
 * Histórico Avançado de Cultura
 * @type {AdvancedHistory}
 *
 * @property {Array<Metrics>} metrics
 * @property {Array<String>} dates
 */

/**
 * Retorna o histórico avaçado de cultura
 * @param {String} groupID Código único do grupo
 * @param {String} behaviorID Identificador do comportamento
 * @returns {AdvancedHistory}
 */
export async function getCultureResultsAdvancedHistory(groupID, behaviorID) {
  return (await api()).get('/engagement/culture/results/advanced-history', {
    params: { groupID, behaviorID },
  })
}

export async function updateCultureCode(data) {
  return (await api()).put(`/engagement/culture/culture-codes/${data.id}`, data)
}

export async function getCultureCode(data) {
  return (await api()).get(`engagement/culture/culture-codes/${data}`)
}

/**
 * Retorna a Listagem de códigos de cultura
 * @param {Number} limit Número máximo de itens para retornar
 * @param {Number} offset Número de itens para pular antes de retornar os resultados
 * @param {String} name Nome para busca do código de cultura
 * @returns {CultureCode}
 */
export async function getCultureCodes(limit, offset, name) {
  return (await api()).get('/engagement/culture/culture-codes', {
    params: { limit, offset, name },
  })
}
/**
 * Deleta um código de cultura
 * @param {cultureCodeId} limit Número máximo de itens para retornar
 * @returns void
 */
export async function deleteCultureCode(cultureCodeID) {
  return (await api()).delete(
    `/engagement/culture/culture-codes/${cultureCodeID}`
  )
}

/**
 * Retorna a Listagem de comportamentos
 * @param {Number} limit Número máximo de itens para retornar
 * @param {Number} offset Número de itens para pular antes de retornar os resultados
 * @param {String} name Nome para busca do comportamento
 * @returns {CultureBehavior}
 */
export async function getCultureBehaviors(limit, offset, name) {
  return (await api()).get('/engagement/culture/behaviors', {
    params: { limit, offset, name },
  })
}

/**
 * @typedef {Object} VariationRanking
 *
 * @property {String} id
 * @property {Number} position
 * @property {Number } positionProbability
 */

/**
 * @typedef {Object} Variation
 *
 * @property {Number} maturity
 * @property {Number} unfavorableCulture
 * @property {Number} culturalAlignment
 * @property {Array<VariationRanking>} ranking
 */

export async function getVariation({ groupID, period = 'real-time' }) {
  const params = {
    period,
  }

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/culture/variation', {
    params,
  })
}

export async function getCultureGroups() {
  return (await api()).get('/engagement/culture/groups/tree')
}

export async function getProjection(data) {
  return (await api()).post('/engagement/culture/results/projection', data)
}

/**
 * @typedef {Object} ReportAttribute
 * @property {String} key
 * @property {String} value
 */

/**
 * @typedef {Object} DemographicReport
 * @property {Array<ReportAttribute>} attributes
 * @property {String} date
 * @property {String} id
 * @property {String} key
 * @property {String} status
 */

/**
 * Retorna a lista de relatórios demográficos
 * @param {String} params.groupID identificador do grupo
 * @returns {Array<DemographicReport>}
 */
export async function getDemographicReportsList({
  groupID = null,
  limit = 20,
  offset = 0,
}) {
  const params = constructParams(['limit', 'offset', 'groupID'], {
    limit,
    offset,
    groupID,
  })

  return (await api()).get('/engagement/culture/demographic-reports', {
    params,
  })
}

export async function deleteDemographicReport(ID) {
  return (await api()).delete(`/engagement/culture/demographic-reports/${ID}`)
}

export async function createDemographicReport(payload) {
  const params = constructParams(['attributes', 'groupID'], payload)

  return (await api()).post(`/engagement/culture/demographic-reports`, params)
}

/**
 *
 * @param {Object} payload
 * @param {String} payload.type Tipo de relatório
 * @param {String} payload.groupID Código único do grupo
 * @returns {Blob}
 */
export async function exportCulture(payload) {
  return (await api()).post('/engagement/culture/results/export', payload, {
    responseType: 'blob',
  })
}
