// initial state
function initialState() {
  return {
    id: null,
    name: null,
    email: null,
    language: null,
    planID: null,
    photo: null,
  }
}

const state = initialState()

// getters
const getters = {
  user: (s) => s,
}

// actions
const actions = {
  loadCurrentUser({ commit }, user) {
    commit('LOAD_CURRENT_USER', user)
  },
}

// mutations
const mutations = {
  async LOAD_CURRENT_USER(currentState, user) {
    const { id, name, email, photo, language, planID } = user

    currentState.id = id
    currentState.name = name
    currentState.email = email
    currentState.language = language
    currentState.planID = planID
    currentState.photo = photo
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
