import Vue from 'vue'

/**
 * @type {import('vuex').Module}
 */
export const ActionPlans = {
  state: () => ({
    ActionPlans: {
      opened: false,

      actionPlanId: null,
      props: {
        planTemplate: null,
      },
    },
  }),

  getters: {
    ActionPlans__Status: ({ ActionPlans }) => {
      return ActionPlans.opened
    },
    ActionPlans__Props: ({ ActionPlans }) => {
      return {
        actionPlanId: ActionPlans.actionPlanId,
        ...ActionPlans.props,
      }
    },
  },

  actions: {
    async openActionPlans({ commit }, opened) {
      commit('SET_ActionPlans__openend', {
        opened: opened,
        actionPlanId: null,
      })

      commit('SET_ActionPlans__Props', {
        planTemplate: null,
      })
    },

    async editActionPlans({ commit }, actionPlanId) {
      commit('SET_ActionPlans__openend', {
        opened: true,
        actionPlanId,
      })

      commit('SET_ActionPlans__Props', {
        planTemplate: null,
      })
    },

    async newActionPlans({ commit }, planTemplate) {
      commit('SET_ActionPlans__openend', {
        opened: true,
        actionPlanId: null,
      })

      commit('SET_ActionPlans__Props', {
        planTemplate,
      })
    },
  },

  mutations: {
    SET_ActionPlans__openend(state, payload) {
      state.ActionPlans.opened = payload.opened
      state.ActionPlans.actionPlanId = payload.actionPlanId
    },

    SET_ActionPlans__Props(state, payload) {
      Vue.set(state.ActionPlans, 'props', payload)
    },
  },
}
