import api from '@/service/Http'
import { constructParams } from '@/helpers/api'

export async function getGroups(data) {
  const params = constructParams(['limit', 'offset', 'name'], data)

  return (await api()).get('/management/groups', {
    params,
  })
}

export async function getGroupsTree() {
  const params = constructParams(['module'], {
    module: 'engagement',
  })

  return (await api()).get('/management/groups/tree', {
    params,
  })
}

export async function listPeopleInTheGroup({ groupID, limit = 5, offset = 0 }) {
  const params = constructParams(['module', 'limit', 'offset'], {
    module: 'engagement',
    limit,
    offset,
  })

  return (await api()).get(`/management/groups/${groupID}/people`, {
    params,
  })
}
