<template>
  <LateralMenuItem :title="$t('opinionsFilter.title')" :is-mini="isMini">
    <template #title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <ComposeForm
      ref="ComposeForm"
      class="filter"
      :form-name="filterName"
      :selectors="{ fields: _fields }"
      :storage-preferences="false"
      v-bind="ComposeFormProps()"
      @compose:values="setFiltersValue($event)"
    ></ComposeForm>
  </LateralMenuItem>
</template>

<script>
import {
  handleAxiosSignalAbortController,
  handleApiRequestWithController,
} from '@/helpers/api'

import { _permissions } from '@/helpers/ability/engagement'

import { getOpinionTags } from '@/service/opinions'
import { getQuestions } from '@/service/climate'

export default {
  name: 'OpinionsFilter',
  props: {
    filterName: {
      type: String,
      required: true,
    },
    isMini: Boolean,
  },
  data() {
    return {
      form: {
        filter: {},
      },

      fields: [
        {
          id: 'period',
          _label: this.$t('opinionsFilter.periodDate'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          default: [
            {
              id: 'month',
              label: this.$t('opinionsFilter.periodDateFilter.month'),
            },
          ],
          options: [
            {
              value: 'period',
              label: '',
              type: 'listview',
              items: [
                {
                  id: 'month',
                  label: this.$t('opinionsFilter.periodDateFilter.month'),
                },
                {
                  id: 'quarter',
                  label: this.$t('opinionsFilter.periodDateFilter.quarter'),
                },
                {
                  id: 'semester',
                  label: this.$t('opinionsFilter.periodDateFilter.semester'),
                },
                {
                  id: 'year',
                  label: this.$t('opinionsFilter.periodDateFilter.year'),
                },
                {
                  id: 'all',
                  label: this.$t('opinionsFilter.periodDateFilter.all'),
                },
              ],
            },
          ],
        },
        {
          id: 'metricID',
          _label: this.$t('opinionsFilter.metric'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'metricID',
              label: '',
              type: 'listview',
              items: [
                {
                  label: this.$t('opinionsFilter.metricFilter.enps'),
                  id: 'enps',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.lnps'),
                  id: 'lnps',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.managerRelationship'
                  ),
                  id: 'managerRelationship',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.managerRelationship:trust'
                  ),
                  id: 'managerRelationship:trust',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.managerRelationship:communication'
                  ),
                  id: 'managerRelationship:communication',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.managerRelationship:collaboration'
                  ),
                  id: 'managerRelationship:collaboration',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.ambassadorship'),
                  id: 'ambassadorship',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.ambassadorship:promotor'
                  ),
                  id: 'ambassadorship:promotor',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.ambassadorship:pride'
                  ),
                  id: 'ambassadorship:pride',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.alignment'),
                  id: 'alignment',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.alignment:values'
                  ),
                  id: 'alignment:values',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.alignment:vision'
                  ),
                  id: 'alignment:vision',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.alignment:ethics'
                  ),
                  id: 'alignment:ethics',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.wellness'),
                  id: 'wellness',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.wellness:stress'),
                  id: 'wellness:stress',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.wellness:personal_health'
                  ),
                  id: 'wellness:personal_health',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.wellness:mental_health'
                  ),
                  id: 'wellness:mental_health',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.personalGrowth'),
                  id: 'personalGrowth',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.personalGrowth:autonomy'
                  ),
                  id: 'personalGrowth:autonomy',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.personalGrowth:mastery'
                  ),
                  id: 'personalGrowth:mastery',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.personalGrowth:purpose'
                  ),
                  id: 'personalGrowth:purpose',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.happiness'),
                  id: 'happiness',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.happiness:work_happiness'
                  ),
                  id: 'happiness:work_happiness',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.happiness:work_balance'
                  ),
                  id: 'happiness:work_balance',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.satisfaction'),
                  id: 'satisfaction',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.satisfaction:compensation'
                  ),
                  id: 'satisfaction:compensation',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.satisfaction:role'
                  ),
                  id: 'satisfaction:role',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.satisfaction:workplace'
                  ),
                  id: 'satisfaction:workplace',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.recognition'),
                  id: 'recognition',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.recognition:recognition_quality'
                  ),
                  id: 'recognition:recognition_quality',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.recognition:recognition_frequency'
                  ),
                  id: 'recognition:recognition_frequency',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.peersRelationship'
                  ),
                  id: 'peersRelationship',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.peersRelationship:collaboration'
                  ),
                  id: 'peersRelationship:collaboration',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.peersRelationship:trust'
                  ),
                  id: 'peersRelationship:trust',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.peersRelationship:communication'
                  ),
                  id: 'peersRelationship:communication',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.feedback'),
                  id: 'feedback',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.feedback:suggestions'
                  ),
                  id: 'feedback:suggestions',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.feedback:quality'
                  ),
                  id: 'feedback:quality',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.feedback:frequency'
                  ),
                  id: 'feedback:frequency',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.diversity'),
                  id: 'diversity',
                },
                {
                  label: this.$t(
                    'opinionsFilter.metricFilter.psychologicalSafety'
                  ),
                  id: 'psychologicalSafety',
                },
                {
                  label: this.$t('opinionsFilter.metricFilter.burnout'),
                  id: 'burnout',
                },
              ],
            },
          ],
        },
        {
          id: 'questionID',
          _label: this.$t('opinionsFilter.question'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          eagerApi: true,
          api: {
            endpoint: getQuestions,
            searchKey: null,
            infinity: false,
            identifier: 'id',
            hidrateFn: (item) => {
              return {
                ...item,
                label: item.text,
              }
            },
          },
        },
        {
          id: 'closed',
          _label: this.$t('opinionsFilter.closed.title'),
          type: 'CardSelect',
          options: [
            {
              id: false,
              msg: this.$t('opinionsFilter.closed.opt1'),
            },
            {
              id: true,
              msg: this.$t('opinionsFilter.closed.opt2'),
            },
          ],
          default: null,

          display: {
            label: true,
            customLabel: this.$t('opinionsFilter.closed.title'),
          },
          CardSelect: {
            borderMode: false,
          },
        },
        {
          id: 'reply',
          _label: this.$t('opinionsFilter.reply.title'),
          type: 'CardSelect',
          options: [
            {
              id: true,
              msg: this.$t('opinionsFilter.reply.opt1'),
            },
            {
              id: false,
              msg: this.$t('opinionsFilter.reply.opt2'),
            },
          ],
          default: null,

          display: {
            label: true,
            customLabel: this.$t('opinionsFilter.reply.title'),
          },
          CardSelect: {
            borderMode: false,
          },
        },
        {
          id: 'readed',
          _label: this.$t('opinionsFilter.readed.title'),
          type: 'CardSelect',
          options: [
            {
              id: true,
              msg: this.$t('opinionsFilter.readed.opt1'),
            },
            {
              id: false,
              msg: this.$t('opinionsFilter.readed.opt2'),
            },
          ],
          default: null,

          display: {
            label: true,
            customLabel: this.$t('opinionsFilter.readed.title'),
          },
          CardSelect: {
            borderMode: false,
          },
        },
        {
          id: 'rated',
          _label: this.$t('opinionsFilter.rated.title'),
          type: 'CardSelect',
          options: [
            {
              id: true,
              msg: this.$t('opinionsFilter.rated.opt1'),
            },
            {
              id: false,
              msg: this.$t('opinionsFilter.rated.opt2'),
            },
          ],
          default: null,

          display: {
            label: true,
            customLabel: this.$t('opinionsFilter.rated.title'),
          },
          CardSelect: {
            borderMode: false,
          },
        },
        {
          id: 'ratedScore',
          _label: this.$t('opinionsFilter.ratedScore'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'ratedScore',
              label: this.$t('actionPlanFilter.metric'),
              type: 'listview',
              items: [
                {
                  id: 'very-unhappy',
                  label: this.$t(
                    'opinionsFilter.ratedScoreFilter.very-unhappy'
                  ),
                },
                {
                  id: 'unhappy',
                  label: this.$t('opinionsFilter.ratedScoreFilter.unhappy'),
                },
                {
                  id: 'no-comments',
                  label: this.$t('opinionsFilter.ratedScoreFilter.no-comments'),
                },
                {
                  id: 'good',
                  label: this.$t('opinionsFilter.ratedScoreFilter.good'),
                },
                {
                  id: 'love-it',
                  label: this.$t('opinionsFilter.ratedScoreFilter.love-it'),
                },
              ],
            },
          ],
        },
        {
          id: 'feeling',
          _label: this.$t('opinionsFilter.feeling.title'),
          type: 'CardSelect',
          options: [
            {
              id: 'positive',
              msg: this.$t('opinionsFilter.feeling.opt1'),
            },
            {
              id: 'constructive',
              msg: this.$t('opinionsFilter.feeling.opt2'),
            },
          ],
          default: null,

          display: {
            label: true,
            customLabel: this.$t('opinionsFilter.feeling.title'),
          },
          CardSelect: {
            borderMode: false,
          },
        },
        {
          id: 'tagsID',
          _label: this.$t('opinionsFilter.tags'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
          },
          ItemSelector: {
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: true,
              clearable: true,
            },
            menuConfig: {
              align: 'right',
              attach: false,
              showTabs: false,
            },
          },
          eagerApi: false,
          api: {
            endpoint: getOpinionTags,
            searchKey: null,
            infinity: true,
            identifier: 'id',
            hidrateFn: (item) => {
              return {
                ...item,
                label: item.label,
              }
            },
          },
        },
        {
          id: 'fromDate',
          _label: this.$t('opinionsFilter.dateFilter'),
          type: 'CustomDatePicker',

          CustomDatePicker: {
            range: true,
            inputConfig: {
              closeOnClick: true,
              rules: [],
            },
            menuConfig: {
              align: 'right',
              attach: false,
            },
            menuProps: {
              top: true,
              nudgeTop: 12,
            },
          },
        },
      ],
    }
  },
  computed: {
    _fields() {
      if (!this._canEngagementOpinionsTags) {
        return this.fields.filter((field) => field.id !== 'tagsID')
      }

      return this.fields
    },
    _canEngagementOpinionsTags() {
      return this.$can('access', _permissions.engagement_opinions_tags)
    },
  },
  beforeMount() {
    this.$root.$on('set-filter:opinions', this.handleFilter)
  },
  beforeDestroy() {
    this.$root.$off('set-filter:opinions', this.handleFilter)
  },
  methods: {
    setFiltersValue(values) {
      this.$set(this.form, 'filter', values)
      this.sendFilterValues(values)
    },

    sendFilterValues(values) {
      let filters = structuredClone(values)

      Object.keys(filters).forEach((key) => {
        if (
          ['period', 'metricID', 'questionID', 'ratedScore', 'tagsID'].includes(
            key
          )
        ) {
          filters[key] = Array.isArray(filters[key])
            ? filters[key]?.[0]?.id
            : null
        }

        if (['fromDate'].includes(key)) {
          filters['toDate'] = filters['fromDate']?.[1] || null
          filters[key] = filters[key]?.[0]
        }
      })

      this.$root.$emit('opinions:filter-changes', filters)
    },

    ComposeFormProps() {
      return {
        handleAxiosSignalAbortController: handleAxiosSignalAbortController,
        handleApiRequestWithController: handleApiRequestWithController,
      }
    },

    handleFilter(payload) {
      if (!this.$refs.ComposeForm) return

      this.$refs.ComposeForm.defineFieldsAsValue(
        {
          ['metricID']: [payload.metricID],
        },
        false
      )

      this.$refs.ComposeForm.setComposeFormPreferences()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
