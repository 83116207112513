var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LateralMenuItem',{attrs:{"title":_vm.$t('demographicReports.smartAttributes.title'),"isMini":_vm.isMini,"howToType":"sidebarSmartAttributes","showCrown":_vm.locked},scopedSlots:_vm._u([{key:"title-icon",fn:function({ size }){return [_c('v-icon',{attrs:{"size":size}},[_vm._v("mdi-flash")])]}}])},[(_vm.locked)?_c('div',{staticClass:"blocked",attrs:{"data-test-smart-attributes-blocked":""},on:{"click":function($event){return _vm.callUpdateYourPackage()}}},[_c('div',{staticClass:"glass"}),_c('div',{staticClass:"blocked-icon"},[_c('i',{staticClass:"fi fi-sr-lock"})])]):_vm._e(),(!_vm.isLoading)?_c('form',{staticClass:"smart-attributes",attrs:{"data-test-smart-attributes-form":""},on:{"submit":function($event){$event.preventDefault();return _vm.generateReport.apply(null, arguments)}}},[_vm._l((_vm._getAttributes),function(attribute){return _c('ItemSelector',{key:attribute.id,attrs:{"data-test-smart-attribute":attribute.label,"inputConfig":{
        label: attribute.label,
        showAvatar: false,
        clearable: true,
      },"inputStyle":'submitMode',"menuConfig":{
        showTabs: false,
      },"menuOptions":[
        {
          value: attribute.id,
          label: attribute.label,
          type: 'listview',
          items: attribute.values,
        },
      ],"currentValue":{
        data: _vm.selectedValues[attribute.id],
        origin: attribute.id,
      },"localSearch":true,"watchCurrent":true,"fillWidth":true,"multiple":true,"readonly":_vm.locked},on:{"update:item":function($event){return _vm.handleSelectAttribute(attribute.id, $event)}}})}),_c('v-btn',{attrs:{"type":"submit","data-test-generate-report":"","text":"","color":"primary","retain-focus-on-click":""}},[_c('v-icon',{attrs:{"size":"10","color":"white"}},[_vm._v("mdi-poll")]),_c('span',[_vm._v(_vm._s(_vm.$t('demographicReports.smartAttributes.generate')))])],1)],2):_vm._e(),(_vm.isLoading && !_vm.locked)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item","height":"30"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item","height":"30"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item","height":"30"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }