// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/300.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/400.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/500.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/600.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/700.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/300-italic.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/400-italic.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/500-italic.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/600-italic.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@fontsource/poppins/700-italic.css";
import ___CSS_LOADER_AT_RULE_IMPORT_10___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../assets/uicons/css/uicons-all.css";
import ___CSS_LOADER_AT_RULE_IMPORT_11___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/@mdi/font/css/materialdesignicons.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{scroll-behavior:smooth}body{font-family:Poppins,sans-serif}body,h1,h2,h3,h4,h5,h6{margin:0}div,ol,p,section,ul{margin:0;padding:0}li,ul{list-style:none}a{text-decoration:none}.theme--light.v-text-field--filled>.v-input__control>.v-input__slot{background:#fff!important}.theme--light.v-application{font-family:Poppins,sans-serif!important;background:#eef2fa!important}.theme--light.v-app-bar.v-toolbar.v-sheet{background:#fff!important;border-radius:.5rem;box-shadow:none}.v-tab{font-size:.875rem!important;color:#2c3a4b;font-weight:400;text-transform:none;font-style:normal}.v-data-table>.v-data-table__wrapper>table{border-collapse:separate!important;border-spacing:0 15px!important}.v-btn{letter-spacing:normal}.v-expansion-panel-content__wrap{padding:0}.v-application--is-ltr .v-expansion-panel-header{padding:0 15px 0}.v-expansion-panel--active>.v-expansion-panel-header{min-height:0}.v-overlay--active .v-overlay__scrim{opacity:1!important;background-color:#0908081a!important;-webkit-backdrop-filter:blur(.25rem);backdrop-filter:blur(.25rem)}.truncated-text,.truncated-text-forced{text-overflow:unset;line-break:anywhere;-webkit-line-clamp:1;overflow:hidden;white-space:nowrap}.truncated-text-forced.text-on-hover,.truncated-text.text-on-hover{text-overflow:ellipsis!important}.full-height{min-height:100vh}.border-neutral,.border-neutral.v-divider,.border-neutral.v-menu__content{border:.0625rem solid #dadee3;border-radius:.375rem}.elevation-neutral,.elevation-neutral.v-menu__content,.elevation-neutral.v-sheet.v-card,.elevation-neutral.v-sheet.v-card:not(.v-sheet--outlined){box-shadow:0 .25rem .5625rem rgba(0,0,0,.05)}.divider-neutral,.divider-neutral.theme--light.v-divider{border:.0625rem solid #f4f6f9}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
