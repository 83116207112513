<template>
  <SideBar @back="toggleShowDetails(false)" @close="toggleShowDetails(false)">
    <template v-slot:title>
      {{ title }}
    </template>

    <template v-slot:body>
      <div class="content-img">
        <v-img height="233px" position="top center" :src="image"></v-img>
      </div>

      <div class="content-text">
        <div class="content-text-header">
          <v-avatar size="36">
            <img
              :src="
                require('@/assets/images/EngagementRecommendationsDetails/what.png')
              "
              :alt="whatSection.title"
            />
          </v-avatar>
          <p>{{ whatSection.title }}</p>
        </div>
        <div
          data-test-recommendationDetailDescription
          class="content-text-description"
          :class="{ show: !hideContent }"
        >
          <p v-for="(c, i) in whatSection.content" :key="i">{{ c }}</p>
          <div
            data-test-recommendationDetailBtnMoreDetails
            @click="showContent"
            v-if="hideContent"
            class="content-text-description__expand"
          >
            {{ $t('engagementRecommendations.showMore') }}
            <i class="fi fi-rr-caret-down ml-2"></i>
          </div>
          <div
            data-test-recommendationDetailBtnLessDetails
            @click="showContent"
            v-if="!hideContent"
            class="content-text-description__expand less"
          >
            {{ $t('engagementRecommendations.showLess') }}
            <i class="fi fi-rr-caret-up ml-2"></i>
          </div>
        </div>
      </div>

      <div class="content-text">
        <div class="content-text-header">
          <v-avatar size="36">
            <img
              :src="
                require('@/assets/images/EngagementRecommendationsDetails/how.png')
              "
              :alt="$t('engagementRecommendations.howToMake')"
            />
          </v-avatar>
          <p>{{ howToSection.title }}</p>
        </div>
        <div class="content-text-container">
          <div
            class="content-text-card"
            v-for="(c, i) in howToSection.content"
            :key="i"
          >
            <v-avatar class="mt-2" size="24" color="#dd0041">
              <span class="white--text text-sm">{{ i + 1 }}</span>
            </v-avatar>
            <div class="content-text-card__content">
              <!-- <p class="content-text-card__title">
                Tenha um lugar para colocar post-its
              </p> -->
              <p class="content-text-card__text">
                {{ c }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="content-text">
        <div class="content-text-header">
          <v-avatar size="36">
            <img
              :src="
                require('@/assets/images/EngagementRecommendationsDetails/benefit.png')
              "
              :alt="$t('engagementRecommendations.benefits')"
            />
          </v-avatar>
          <p>{{ benefitsSection.title }}</p>
        </div>
        <div class="content-text-description">
          <p v-for="(b, i) in benefitsSection.content" :key="i">{{ b }}</p>
        </div>
      </div>

      <v-spacer></v-spacer>
    </template>

    <template v-slot:footer>
      <div class="content-footer-actions avaliation">
        <p class="text">
          {{ $t('engagementRecommendations.moreDetails.help') }}
        </p>
        <div
          data-test-recommendationDetailDislike
          class="dislike"
          @click="vote(false)"
        >
          <i v-if="approval === null || approval" class="fi fi-rr-hand"></i>
          <i
            v-if="approval !== null && !approval"
            class="fi fi-sr-thumbs-down"
          ></i>
        </div>
        <div
          data-test-recommendationDetailLike
          class="like"
          @click="vote(true)"
        >
          <i
            v-if="approval === null || !approval"
            class="fi fi-rr-social-network"
          ></i>
          <i
            v-if="approval !== null && approval"
            class="fi fi-sr-thumbs-up"
          ></i>
        </div>
      </div>

      <v-spacer />

      <div class="content-footer-actions">
        <v-btn
          v-if="_canNewActionPlan"
          width="208"
          class="content-footer-actions__btn elevation-0"
          @click="callCreatePlan()"
        >
          {{ $t('engagementRecommendations.moreDetails.createPlan') }}
        </v-btn>
      </div>
    </template>
  </SideBar>
</template>

<script>
import SideBar from '@/components/SideBar/SideBar.vue'

import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'EngagementRecommendationsMoreDetails',
  components: { SideBar },

  inject: ['toggleShowDetails', 'actionPlan'],

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    whatSection: {
      type: Object,
      required: true,
    },
    howToSection: {
      type: Object,
      required: true,
    },
    benefitsSection: {
      type: Object,
      required: true,
    },
    approval: {
      type: [String, Boolean],
      default: null,
    },
  },
  data: () => ({
    hideContent: true,
  }),

  computed: {
    _canNewActionPlan() {
      return (
        this._canEngagementActionPlansCreator ||
        this._canEngagementActionPlansTemplatesCreator
      )
    },

    _canEngagementActionPlansCreator() {
      return this.$can('access', _permissions.engagement_action_plans_creator)
    },
    _canEngagementActionPlansTemplatesCreator() {
      return this.$can(
        'access',
        _permissions.engagement_action_plans_templates_creator
      )
    },
  },

  methods: {
    showContent() {
      this.hideContent = !this.hideContent
    },
    vote(type) {
      this.$emit('voted', this.$props.id, type)
    },
    callCreatePlan() {
      const payload = {
        title: this.title,
      }

      this.actionPlan.new = payload
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
