<template>
  <v-app>
    <div :class="{ 'filter-blur': filterBlur }">
      <LateralMenu v-if="isSignedin && _showLateralMenu"></LateralMenu>

      <NavBar
        v-if="isSignedin && !_showNavBar"
        :user="user"
        :user-links="_navBarLinks.userLinks"
        :menu-links="_navBarLinks.menuLinks"
        :has-lateral-action="true"
      >
        <template #footer="{ isMiniVariant }">
          <ToLegacy
            v-if="!_canAppHideLegacyButton"
            :is-mini-variant="isMiniVariant"
            :legacy-u-r-l="_legacyURL"
          ></ToLegacy>

          <LanguageMenu
            :current-language="_currentLanguage"
            :is-mini-variant="isMiniVariant"
            @selected-language="handleSyncLanguage"
          ></LanguageMenu>

          <HelpAxel
            :is-mini-variant="isMiniVariant"
            :go-help-axel="goHelpAxel"
          ></HelpAxel>
        </template>
      </NavBar>

      <v-main class="page" :class="{ modal: showDetails }">
        <v-container fluid :class="checkIfPaddingIsRequired">
          <LoadingParcel v-if="isLoading"></LoadingParcel>

          <transition>
            <router-view
              v-show="!isLoading"
              ref="PageView"
              class="full-height"
            ></router-view>
          </transition>
        </v-container>
      </v-main>
    </div>

    <ExplanationNPS
      :is-open="getExplanationNPS_Status"
      :type="getExplanationNPSType"
      @update:isOpen="closeExplanationNPS()"
    ></ExplanationNPS>

    <DialogBenchmarkRank
      :is-open="getOverallAverageStatus"
      @update:isOpen="handleBenchmarkRankingDialog"
    ></DialogBenchmarkRank>

    <OpinionBox
      mode-sidebar
      data-test-sidebar-opinion-box
      :is-open="getOpinionBoxStatus"
      :opinion="getOpinionBoxProps.selectedOpinion.data"
      :owner-identity="getOpinionBoxProps.selectedOpinion.ownerIdentity"
      :chunks="getOpinionBoxProps.selectedOpinion.chunks.data"
      :chunks-loading="getOpinionBoxProps.selectedOpinion.chunks.loading"
      :tags="getOpinionBoxProps.tags"
      @update:isOpen="closeOpinionBox()"
      @handleMarkAsReaded="getOpinionBoxProps.handleMarkAsReaded"
      @handleMarkAsUnread="getOpinionBoxProps.handleMarkAsUnread"
      @handleSendMessage="getOpinionBoxProps.handleSendMessage"
      @handleCloseOpinion="getOpinionBoxProps.handleCloseOpinion"
      @handleTagsInfiniteScroll="getOpinionBoxProps.handleTagsInfiniteScroll"
      @handleAddTag="getOpinionBoxProps.handleAddTag"
      @handleRemoveTag="getOpinionBoxProps.handleRemoveTag"
      @handleCreateTag="getOpinionBoxProps.handleCreateTag"
    ></OpinionBox>

    <EngagementRecommendationsMoreDetails
      v-if="showDetails"
      :id="RecommendationsDetails.sections.id"
      :approval="RecommendationsDetails.approvalDetails"
      :title="RecommendationsDetails.sections.title"
      :image="RecommendationsDetails.sections.image"
      :what-section="RecommendationsDetails.sections.what"
      :how-to-section="RecommendationsDetails.sections.how"
      :benefits-section="RecommendationsDetails.sections.benefit"
      @voted="callVoteApproval"
    ></EngagementRecommendationsMoreDetails>

    <ActionPlans
      v-if="showActionPlan"
      :plan-i-d="ActionPlansDetails.actionPlanId"
      :plan-template="ActionPlansDetails.planTemplate"
    ></ActionPlans>

    <UpdateYourPackage
      :is-open="getUpdateYourPackageStatus"
      :type="getUpdateYourPackageType"
      @dialog:close="closeUpdateYourPackageDialog()"
      @dialog:action="actionUpdateYourPackageDialog()"
    ></UpdateYourPackage>

    <AlertBar></AlertBar>
  </v-app>
</template>

<script>
import { projectAuth, LocalStorageToken } from '@/firebase/config'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { getAccount, getAccountMe, putAccountMe } from '@/service/account'

import { abilityHelper } from '@/helpers/ability'
import { logEventHandler } from '@/helpers/analytics'
import {
  calcLanguageToApi,
  calcLanguageToLocale,
  saveLanguage,
} from '@/helpers/locale'

import { genSiteNavigationItens } from '@/helpers/menu-itens'

import AlertBar from '@/components/AlertBar/AlertBar.vue'
import DialogBenchmarkRank from '@/components/DialogBenchmarkRank/DialogBenchmarkRank.vue'
import EngagementRecommendationsMoreDetails from '@/components/EngagementRecommendationsMoreDetails/EngagementRecommendationsMoreDetails.vue'
import ExplanationNPS from '@/components/ExplanationNPS/ExplanationNPS.vue'
import OpinionBox from '@/components/OpinionBox/OpinionBox.vue'
import UpdateYourPackage from '@/components/UpdateYourPackage/UpdateYourPackage.vue'

import LoadingParcel from '@/pages/Login/Partials/Loading.vue'

import ActionPlans from '@/pages/EngagementPage/Views/ActionPlans/ActionPlans.vue'
import LateralMenu from '@/pages/EngagementPage/Views/LateralMenu/LateralMenu.vue'
import { debounce } from 'lodash'

import * as _canKey from '@/helpers/ability/permissions'

const MainPageRouterName = 'EngagementPage'
export default {
  name: 'App',
  components: {
    ActionPlans,
    AlertBar,
    DialogBenchmarkRank,
    EngagementRecommendationsMoreDetails,
    ExplanationNPS,
    LateralMenu,
    OpinionBox,
    UpdateYourPackage,
    LoadingParcel,
  },

  provide() {
    const actionPlan = this.genProvideActionPlan()

    return {
      actionPlan,
      callUpdateYourPackage: this.callUpdateYourPackage,
      toggleShowDetails: this.toggleShowDetails,
      changeLanguage: this.handleChangeLanguage,
    }
  },

  data() {
    return {
      callHandleSignIn: debounce(async function () {
        await this.handleSignIn()
      }, 250),
      isLoading: true,

      filterBlur: false,
      hideNavBarbyRouter: [
        'Login',
        'NotFoundPage',

        'SurveyInit',
        'SurveySteps',
        'SurveyFeedback',
        'SurveyFinish',
        'SurveyForbidden',
        'SurveyOther',
      ],

      foreignPages: [
        'Login',
        'NotFoundPage',

        'SurveyInit',
        'SurveySteps',
        'SurveyFeedback',
        'SurveyFinish',
        'SurveyForbidden',
        'SurveyOther',
      ],
    }
  },

  head() {
    return {
      htmlAttrs: {
        lang: this._currentLanguage,
      },
      title: this._currentTitle,
    }
  },

  computed: {
    ...mapGetters([
      'getUserDetailsLoggable',

      'getUpdateYourPackageStatus',
      'getUpdateYourPackageType',
      'getPaymentMethod',

      'getExplanationNPS_Status',
      'getExplanationNPSType',
      'getOverallAverageStatus',

      'getOpinionBoxStatus',
      'getOpinionBoxProps',
    ]),
    ...mapGetters({
      showDetails: 'RecommendationsMoreDetails__Status',
      RecommendationsDetails: 'RecommendationsMoreDetails__Props',

      showActionPlan: 'ActionPlans__Status',
      ActionPlansDetails: 'ActionPlans__Props',

      isSignedin: 'getIsSignedIn',
      user: 'currentUser/user',
    }),

    _legacyURL() {
      return new URL(process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK).toString()
    },

    _currentTitle() {
      const title = this.$route.matched.findLast(
        (record) => record?.meta?.title
      )

      if (!title) {
        return undefined
      }

      return `${this.$t(title.meta.title)} - TeamCulture`
    },
    _currentLanguage() {
      return this.$i18n.locale
    },
    _navBarLinks() {
      const { userLinks, menuLinks } = genSiteNavigationItens(this.logout)

      return this.calcNavBarLinksByPermissions(userLinks, menuLinks)
    },

    padding: function () {
      return this.foreignPages.indexOf(this.$route.name) < 0 ? 'padding' : ''
    },
    checkIfPaddingIsRequired: function () {
      return this.foreignPages.indexOf(this.$route.name) < 0
        ? 'padding-content'
        : 'pa-0'
    },
    _showLateralMenu() {
      const baseRouteName = this.$route.matched?.[0]?.name ?? this.$route.name

      const shouldHide = (term) =>
        ['MyOpinions', 'ListOpinionsPage', 'SentOpinionsPage'].includes(term)

      if (shouldHide(this.$route.name) || shouldHide(baseRouteName)) {
        return false
      }

      return [MainPageRouterName].includes(baseRouteName)
    },

    _showNavBar() {
      return this.hideNavBarbyRouter.includes(this.$route.name)
    },

    _canAppHideLegacyButton() {
      return this.$can('access', 'app.hide.legacy-button')
    },
  },

  created() {
    this.$root.$on('handle-blur', () => {
      this.handleBlur()
    })

    this.handleChangeLanguage(false)
  },

  mounted() {
    this.onAuthStateChangedByLogin()
  },

  methods: {
    ...mapActions([
      'setAccount',
      'setSignedInLogin',
      'setSignedInLogout',

      'openRecommendationsMoreDetails',
      'closeRecommendationsMoreDetails',
      'voteRecommendationsMoreDetails',

      'openActionPlans',
      'editActionPlans',
      'newActionPlans',
    ]),
    ...mapMutations(['changeClientPackage']),

    goHelpAxel() {
      const $sleek = window?.$sleek

      if ($sleek) {
        $sleek.open()
        return
      }

      window.open(
        this.$t('ui-components.siteNavigation.helpInfoLink'),
        '_blank'
      )
    },

    exitLoadingStage() {
      this.isLoading = false
    },

    genProvideActionPlan() {
      const provide = {}

      Object.defineProperty(provide, 'open', {
        enumerable: true,
        get: () => this.showActionPlan,
        set: (opened) => {
          this.openActionPlans(opened)
        },
      })

      Object.defineProperty(provide, 'edit', {
        enumerable: true,
        get: () => this.showActionPlan,
        set: (actionPlanId) => {
          this.editActionPlans(actionPlanId)
        },
      })

      Object.defineProperty(provide, 'new', {
        enumerable: true,
        get: () => this.showActionPlan,
        set: (planTemplate) => {
          this.newActionPlans(planTemplate)
        },
      })

      return provide
    },

    callUpdateYourPackage(type) {
      if (!this.isSignedin) {
        return
      }

      this.$store.commit('openUpdateYourPackageDialog', type)
    },
    closeUpdateYourPackageDialog() {
      this.$store.commit('closeUpdateYourPackageDialog')
    },
    actionUpdateYourPackageDialog() {
      // TODO : redirect to chat.teamculture.com.br
      // this.$crisp.push(['do', 'chat:open'])
      // this.$crisp.push([
      //   'do',
      //   'message:send',
      //   ['text', this.$t('updateYourPackage.chatMessage')],
      // ])
    },

    closeExplanationNPS() {
      this.$store.commit('closeExplanationNPSDialog')
    },
    async toggleShowDetails(opened, recommendationId) {
      if (!opened) {
        this.closeRecommendationsMoreDetails()
        return Promise.resolve()
      }

      return await this.openRecommendationsMoreDetails(recommendationId)
    },
    async callVoteApproval(id, vote) {
      return await this.voteRecommendationsMoreDetails({ id, vote })
    },

    handleBenchmarkRankingDialog() {
      this.$store.commit('handleBenchmarkRankingDialog')
    },
    handleBlur() {
      this.filterBlur = !this.filterBlur
    },
    closeOpinionBox() {
      this.$store.commit('closeOpinionBox')
    },

    async fetchAccount() {
      return getAccount().then(({ data }) => this.setAccount(data))
    },
    onAuthStateChangedByLogin() {
      // wait for login
      this.setSignedInLogout()

      projectAuth.onAuthStateChanged(this.onAuthStateChangedNextOrObserver)
    },
    /**
     * @param {import('@firebase/auth').User} user
     */
    async onAuthStateChangedNextOrObserver(user) {
      if (!user) {
        this.exitLoadingStage()
        this.setSignedInLogout()
        this.$store.dispatch('currentUser/loadCurrentUser', {})

        this.callHandleSignIn.cancel()
        return
      }

      await this.$nextTick()

      this.callHandleSignIn()
    },

    handleLoginPageMethods(methodName, ...payload) {
      this.$nextTick(() => {
        if (!window.location.href.includes('login')) {
          return
        }

        this.exitLoadingStage()

        const method = this.$refs.PageView?.[methodName]
        if (typeof method !== 'function') {
          return
        }

        method(...payload)
      })
    },

    handleFromLoginToPage() {
      const from = sessionStorage.getItem('interface-recovery--redirectPath')

      if (from) {
        const { to, toQuery } = JSON.parse(from)
        sessionStorage.removeItem('interface-recovery--redirectPath')

        this.exitLoadingStage()
        this.$router.push({ ...to, query: toQuery })

        return
      }

      if (!window.location.href.includes('login')) {
        return
      }

      this.handleRouteGuard(this.$route.matched)
    },

    defineRouteGuardWatch() {
      return this.$watch('$route.matched', this.handleRouteGuard, {
        immediate: true,
      })
    },

    async handleSignIn() {
      this.handleLoginPageMethods('setLoading', true)

      await getAccountMe()
        .then(async ({ data }) => {
          const client = {
            ...data,
            paymentMethod: 'other',
            package: data.planID,
          }
          this.$store.dispatch('setClient', client)
          this.$store.dispatch('currentUser/loadCurrentUser', data)

          const lang = calcLanguageToLocale(data.language)
          this.handleChangeLanguage(lang)

          // abilityHelper(require('@/helpers/ability/engagement').permissions) // @TODO Granulado
          // abilityHelper([]) // @TODO Em Branco
          abilityHelper(data.permissions)

          this.setSignedInLogin()

          await this.fetchAccount()

          this.handleSleek(data)

          this.defineRouteGuardWatch()

          this.handleFromLoginToPage()
        })
        .catch(() => {
          this.handleLoginPageMethods('setLoading', false)
          this.handleLoginPageMethods('customErrorMessage')

          // User is signed out.
          this.logout()
        })
    },
    async logout() {
      return new Promise((resolve) => {
        projectAuth.signOut().then(() => {
          this.exitLoadingStage()
          this.setSignedInLogout()

          if (!window.location.href.includes('login')) {
            localStorage.removeItem(LocalStorageToken)
            window.location = `${process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK}/logout`
          }

          resolve()
        })
      })
    },

    calcRoutePermissions(matched) {
      const requiresPermissions = Array.from(
        new Set(matched.flatMap((record) => record?.meta?.requiresPermission))
      ).filter(Boolean)

      const canPagePermissions = requiresPermissions.reduce(
        (obj, permission) => {
          const canPermission = this.$can('access', permission)
          return { ...obj, [permission]: canPermission }
        },
        {}
      )

      return canPagePermissions
    },
    async handleRouteGuard(matched) {
      const canPagePermissions = this.calcRoutePermissions(matched)
      const shouldGuardRoute = Object.keys(canPagePermissions).length
      const hasPermission = Object.values(canPagePermissions).every(
        (permission) => permission === true
      )

      if (shouldGuardRoute && hasPermission) {
        this.logEventFirebase()
      }

      if (
        (!shouldGuardRoute || hasPermission) &&
        this.$route.name !== MainPageRouterName
      ) {
        this.exitLoadingStage()
      }

      if (
        (shouldGuardRoute && !hasPermission) ||
        this.$route.name === MainPageRouterName
      ) {
        this.goNextRoutePossible(MainPageRouterName)
      }
    },

    goNextRoutePossible() {
      const PageRouter = this.$router.options.routes.find(
        (r) => r.name === MainPageRouterName
      )

      const canAccess = (routePermission) => {
        if (typeof routePermission !== 'boolean') {
          return Object.values(routePermission).some((childPermission) =>
            canAccess(childPermission)
          )
        }

        return routePermission
      }

      const getRoutePermissions = (Route) =>
        Route?.children?.reduce((obj, page) => {
          if (page.path.includes(':')) {
            return { ...obj }
          }

          const matched = [Route, page]
          let hasPermission = Object.values(
            this.calcRoutePermissions(matched)
          ).every((permission) => permission === true)

          if (page?.children) {
            const children = getRoutePermissions(page)

            if (Object.keys(children).length) {
              const hasPermissionChildren = Object.values(children).some(
                (permission) => canAccess(permission)
              )

              hasPermission = hasPermissionChildren ? children : false
            }
          }

          return { ...obj, [page.name]: hasPermission }
        }, {}) || {}
      const PageChilds = getRoutePermissions(PageRouter)

      let nextRoutePossible
      const getNextRoutePossible = (Routes) =>
        Object.keys(Routes).some((permission) => {
          if (typeof Routes[permission] !== 'boolean') {
            return getNextRoutePossible(Routes[permission])
          }

          if (Routes[permission]) {
            nextRoutePossible = permission
          }

          return Routes[permission]
        })
      getNextRoutePossible(PageChilds)

      if (!nextRoutePossible && this.$can('access', _canKey.profile)) {
        return window.location.assign('/profile')
      }

      if (!nextRoutePossible) {
        this.logout().then(() => {
          this.$nextTick(() => {
            this.$refs.PageView?.customErrorMessage({
              text: 'LoginPage.errorPermission.text',
            })
          })
        })

        return
      }

      this.handleNextPossibleRouter(nextRoutePossible)
    },

    isValidNextPossibleRouter(nextRoutePossible) {
      return this.$route.matched.some((r) => r.name === nextRoutePossible)
    },

    handleNextPossibleRouter(nextRoutePossible) {
      setTimeout(() => {
        if (this.isValidNextPossibleRouter(nextRoutePossible)) {
          this.exitLoadingStage()
          return
        }

        this.$router.push(
          { name: nextRoutePossible },
          () => {
            this.exitLoadingStage()
          },
          () => {}
        )
      }, 0)
    },

    logEventFirebase() {
      const context =
        {
          ClimatePage: 'engagement_climate',
          CulturePage: 'engagement_culture',
          ParticipationPage: 'engagement_participation',
          ComparisonPage: 'engagement_comparison',
          BenchmarkPage: 'engagement_benchmark_indicators',
          IndicatorsPage: 'engagement_benchmark_indicators',
          ReceivedOpinionsPage: 'engagement_opinions_receivedopinions',
          ActionPlanPage: 'engagement_actionplans',
          ActionPlansPage: 'engagement_actionplans',
        }[this.$route.name] || ''

      if (context) {
        logEventHandler(context, { ...this.getUserDetailsLoggable })
      }
    },

    async handleSyncLanguage(lang) {
      const language = calcLanguageToApi(lang)
      await putAccountMe({ language })
        .then(() => {
          this.handleChangeLanguage(lang)
        })
        .catch(() => {})
    },

    /**
     * @param {String} lang
     */
    async handleChangeLanguage(lang) {
      const { language, reloadByNotEqSession } = saveLanguage(lang)
      this.$root.$i18n.locale = language

      if (!reloadByNotEqSession) {
        return
      }

      const { l: urlLang = false } = this.$route.query
      const preventUrlNotEqSession = calcLanguageToLocale(urlLang) !== language
      if (urlLang && preventUrlNotEqSession) {
        return
      }

      window.location.reload()
    },

    getViewLinkPermissioned(link) {
      if (Array.isArray(link?.permissioned)) {
        return link.permissioned.some((perm) => this.$can('access', perm))
      }

      return !link?.permissioned || this.$can('access', link.permissioned)
    },

    calcNavBarLinksByPermissions(userLinks, menuLinks) {
      const menuLinksByPermissions = menuLinks
        .map((link) => {
          let showLink = this.getViewLinkPermissioned(link)

          if (link?.newLayout) {
            const useNewLayout =
              !link?.newLayout?.permissioned ||
              this.$can('access', link.newLayout.permissioned)

            if (useNewLayout) {
              return {
                ...link,
                ...link?.newLayout,
              }
            }
          }

          if (!showLink) {
            return null
          }

          return link
        })
        .filter(Boolean)

      return {
        userLinks,
        menuLinks: menuLinksByPermissions,
      }
    },

    handleSleek(user) {
      const $sleek = window?.$sleek

      if (!$sleek) {
        return
      }

      const {
        email,
        name,
        planID,
        isAdmin = false,
        accountName,
        accountPeople,
      } = user

      $sleek.setUser({
        id: email,
        name,
        mail: email,
        token: email,
        weight: isAdmin ? 10 : 1,
      })

      $sleek.addMetadata({
        account_name: accountName,
        account_people: accountPeople,
        account_planID: planID,
        person_admin: isAdmin,
      })

      $sleek.hideButton()
    },
  },
}
</script>
