<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :min-width="218"
    transition="slide-x-transition"
    bottom
    left
    offset-y
    nudge-bottom="6"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :x-small="small"
        fab
        icon
        class="opinion-box__add-tag-icon"
        v-bind="attrs"
        v-on="on"
      >
        <i aria-hidden="true" class="fir fi-rr-add"></i>
      </v-btn>
    </template>

    <div class="opinion-box__add-tag-menu" data-test-opinion-tag-menu>
      <div class="opinion-box__add-tag-menu-header">
        <input
          v-model="searchTag"
          class="opinion-box__add-tag-menu-input search-tag"
          :placeholder="$t('opinionTagMenu.addTagPlaceholder')"
          type="text"
        />

        <v-btn
          fab
          icon
          @click="menu = false"
          class="opinion-box__add-tag-menu-close"
        >
          <i aria-hidden="true" class="fi fi-br-cross-small"></i>
        </v-btn>
      </div>

      <div class="opinion-box__add-tag-menu-body">
        <ul
          v-on:scroll="handleInfiniteScroll"
          class="opinion-box__add-tag-menu-list"
        >
          <template v-for="tag in filteredTags">
            <li
              :id="`ob-checkbox-${tag.id}`"
              class="opinion-box__add-tag-menu-item"
              :key="tag.id"
              :class="[activeTags.includes(tag.id) ? 'selected' : '']"
            >
              <v-checkbox
                v-model="activeTags"
                :readonly="tagReadonlyMap[tag.id]"
                @change="manipulateTag(tag, $event)"
                :value="tag.id"
                :label="tag.label"
                :ripple="false"
                hide-details
                indeterminate-icon=""
                on-icon="fi-rr-check"
                off-icon=""
              >
              </v-checkbox>
            </li>
          </template>
        </ul>
      </div>

      <div class="opinion-box__add-tag-menu-footer">
        <span
          text
          class="opinion-box__add-tag-menu-footer-action"
          @click="isCreatingNewTag = true"
          v-if="!isCreatingNewTag"
          >{{ $t('opinionTagMenu.addTag') }}</span
        >

        <input
          type="text"
          class="opinion-box__add-tag-menu-input new-tag"
          :placeholder="$t('opinionTagMenu.addTagPlaceholder')"
          @keydown.enter="createTag()"
          v-model="newTag"
          v-else
        />
      </div>
    </div>
  </v-menu>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'OpinionTagMenu',
  data() {
    return {
      menu: false,
      searchTag: '',
      newTag: '',
      isCreatingNewTag: false,
      activeTags: this.opinionTags.map((tag) => tag.id),
      tagReadonlyMap: [],
    }
  },
  props: {
    tags: {
      type: Object,
      default: () => ({
        data: [],
      }),
    },
    opinionTags: {
      type: Array,
      default: () => [],
    },
    small: {
      type: Boolean,
      default: false,
    },
    opinionID: {
      type: String,
      default: '',
    },
  },
  watch: {
    menu(newVal) {
      if (newVal === false && this.newTag.trim() === '')
        this.isCreatingNewTag = false
    },
    opinionTags(newVal) {
      this.activeTags = newVal.map((tag) => tag.id)
    },
  },
  computed: {
    filteredTags() {
      let tempTags = this.tags.data

      if (this.searchTag) {
        tempTags = tempTags.filter((tag) => {
          return tag.label.toUpperCase().includes(this.searchTag.toUpperCase())
        })
      }

      return tempTags
    },
  },
  methods: {
    manipulateTag(tag, _event) {
      Vue.set(this.tagReadonlyMap, tag.id, true)

      let diference = this.opinionTags.filter((t) => t.id === tag.id)
      if (diference.length === 1) {
        this.$emit('handleRemoveTag', { tag, opinionID: this.opinionID })
      } else {
        this.$emit('handleAddTag', { tag, opinionID: this.opinionID })
      }

      setTimeout(() => {
        Vue.set(this.tagReadonlyMap, tag.id, false)
      }, 1000)
    },
    createTag() {
      if (this.newTag.trim() === '') return

      this.$emit('handleCreateTag', {
        label: this.newTag,
        opinionID: this.opinionID,
      })

      this.newTag = ''
    },
    handleInfiniteScroll(params) {
      if (
        params.target.clientHeight + params.target.scrollTop >=
        params.target.scrollHeight
      ) {
        this.$emit('handleTagsInfiniteScroll')
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
