<template>
  <div data-test-sidebar-action-plans>
    <LateralMenuItem
      :title="$t('actionPlans.title')"
      :isMini="isMini"
      howToType="sidebarActionPlans"
    >
      <template v-slot:title-icon="{ size }">
        <v-icon :size="size">mdi-text</v-icon>
      </template>
      <template v-if="_canCreate" v-slot:header-action>
        <v-btn
          class="pa-0 ma-0"
          icon
          small
          @click="createActionPlan"
          data-test-create-action-plan
        >
          <i class="fi fi-br-plus"></i>
        </v-btn>
      </template>

      <div id="actionPlansContent" data-test-action-plans-content>
        <div
          data-test-action-plans-blocked
          class="blocked"
          v-if="locked"
          @click="callUpdateYourPackage()"
        >
          <div class="glass"></div>
          <div class="blocked-icon">
            <i class="fi fi-sr-lock"></i>
          </div>
        </div>

        <div
          v-for="plan in _actionPlansList"
          :key="plan.id"
          class="action-plan-container"
          data-test-action-plan-item
        >
          <div class="action-plan-content-container">
            <TruncatedArea>
              <template #inside>
                <span class="action-plan-title" v-text="plan.title"></span>
              </template>
              <template #default>
                <span v-text="plan.title"></span>
              </template>
            </TruncatedArea>

            <p data-test-action-plan-metric>
              {{ $t('actionPlans.pillar') }}:
              <span>{{ plan.metric.label }}</span>
            </p>
            <v-row class="mx-0">
              <v-col class="px-0">
                <div class="d-flex flex-column align-start">
                  <p v-text="$t('actionPlans.initial')"></p>
                  <div
                    :class="[
                      'action-plan-score',
                      getScoreClass(
                        plan.progress.initial,
                        plan.type,
                        plan.metric.id
                      ),
                    ]"
                    data-test-action-plan-initial
                    v-text="formatScore(plan.progress.initial, plan.type)"
                  ></div>
                </div>
              </v-col>
              <v-col class="px-0">
                <div class="d-flex flex-column align-start">
                  <p v-text="$t('actionPlans.current')"></p>
                  <div
                    :class="[
                      'action-plan-score',
                      getScoreClass(
                        plan.progress.current,
                        plan.type,
                        plan.metric.id
                      ),
                    ]"
                    data-test-action-plan-current
                    v-text="formatScore(plan.progress.current, plan.type)"
                  ></div>
                </div>
              </v-col>
              <v-col class="px-0">
                <div class="d-flex flex-column align-start">
                  <p v-text="$t('actionPlans.goal')"></p>
                  <div
                    :class="[
                      'action-plan-score',
                      getScoreClass(
                        plan.progress.goal,
                        plan.type,
                        plan.metric.id
                      ),
                    ]"
                    data-test-action-plan-goal
                    v-text="formatScore(plan.progress.goal, plan.type)"
                  ></div>
                </div>
              </v-col>
              <v-col class="px-0">
                <div class="d-flex flex-column align-start">
                  <p v-text="$t('actionPlans.tasks')"></p>
                  <div class="action-plan-tasks" data-test-action-plan-tasks>
                    <span>
                      {{ plan.tasks.completed }}
                    </span>
                    /
                    {{ plan.tasks.total }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div class="action-plan-edit-container">
            <v-btn
              icon
              @click="() => editActionPlan(plan)"
              data-test-edit-action-plan
            >
              <v-icon size="14">mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>

        <div
          v-if="_actionPlansList.length === 0 && !showLoading"
          class="empty-action-plan"
          data-test-empty-action-plans
        >
          <p v-text="$t('actionPlans.emptyActionPlansDesc')"></p>
          <a
            v-if="_canCreate"
            @click="createActionPlan"
            href="#"
            data-test-empty-create-action-plan
            v-text="$t('actionPlans.createFirstActionPlan')"
          ></a>
        </div>

        <div>
          <v-row v-if="displayShowMoreAction">
            <v-col>
              <div
                class="show-more-container"
                @click="showMoreActionPlans"
                data-test-show-more-action-plans
              >
                {{ $t('actionPlans.showMore') }}
                <i class="fi fi-sr-caret-down"></i>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="showLoading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
      </div>
    </LateralMenuItem>
  </div>
</template>

<script>
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'ActionPlans',

  inject: ['actionPlan'],
  props: {
    actionPlans: Array,
    showLoading: Boolean,
    isMini: Boolean,
    groupID: String,
    locked: Boolean,
    currentContext: String,
    displayShowMoreAction: Boolean,
  },

  computed: {
    _actionPlansList() {
      if (!this.locked) {
        return this.actionPlans
      }

      const noPermissionPlan = {
        id: 'no-permission-ap',
        title: 'Action plan',
        tasks: {
          total: 0,
          completed: 0,
        },
        metric: {
          label: 'Metric action plan',
          id: 'maturityLevel',
        },
        progress: {
          initial: 2,
          current: 5,
          goal: 8,
        },
        type: this.currentContext,
      }

      const noPermissionPlan2 = {
        ...noPermissionPlan,
        id: 'no-permission-ap-2',
      }
      return [noPermissionPlan, noPermissionPlan2]
    },

    _canCreate() {
      return (
        this._canEngagementActionPlansCreator ||
        this._canEngagementActionPlansTemplatesCreator
      )
    },

    _canEngagementActionPlansCreator() {
      return this.$can('access', _permissions.engagement_action_plans_creator)
    },
    _canEngagementActionPlansTemplatesCreator() {
      return this.$can(
        'access',
        _permissions.engagement_action_plans_templates_creator
      )
    },
  },

  methods: {
    callUpdateYourPackage() {
      this.$store.commit('openUpdateYourPackageDialog', 'action')
    },
    getScoreClass(score, planType, metricID) {
      const scoreType = planType === 'culture' ? metricID : planType

      const colorCuts = {
        participation: { medium: 50, high: 75 },
        climate: { medium: 5, high: 7.5 },
        maturityLevel: { medium: 3, high: 4 },
        culturalAlignment: { medium: 40, high: 60 },
        unfavorableCulture: { medium: 30, high: 10 },
      }

      const cut = colorCuts[scoreType]

      const isUnfavorableCulture = scoreType === 'unfavorableCulture'
      if (isUnfavorableCulture) {
        return this.getUnfavorableScoreClass(score, cut)
      }

      return this.getCultureScoreClass(score, cut)
    },
    getUnfavorableScoreClass(score, cut) {
      if (score > cut.medium) {
        return 'low-score'
      }

      if (score <= cut.medium && score > cut.high) {
        return 'medium-score'
      }

      return 'high-score'
    },
    getCultureScoreClass(score, cut) {
      if (score < cut.medium) {
        return 'low-score'
      }

      if (score >= cut.medium && score < cut.high) {
        return 'medium-score'
      }

      return 'high-score'
    },

    formatScore(score, type) {
      if (!score) {
        return '--'
      }

      if (['participation', 'culture'].includes(type)) {
        return `${score}%`
      }

      return score
    },

    async showMoreActionPlans() {
      this.$emit('on-show-more-click', { showMore: true })
    },

    async createActionPlan() {
      if (this.locked) {
        return
      }

      const payload = { type: this.currentContext }

      if (this.groupID) {
        payload.group = { id: this.groupID }
      }

      this.actionPlan.new = payload
    },
    editActionPlan(plan) {
      if (this.locked) {
        return
      }

      this.actionPlan.edit = plan.id
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
